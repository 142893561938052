import { api } from "../utils/axios";

const handleBirthday = (date) => {
    const [day, month, year] = date.split('/');
    return `${month}/${day}/${year}`;
}

export async function sendPet(formData) {

    const formDataObj = Object.fromEntries(formData)

    const formDataBody = new FormData()

    formDataBody.append('beneficiary[name]', formDataObj.Nome)
    formDataBody.append('beneficiary[cpf]', formDataObj.cpf.replace(/[.-]/g, ''))
    formDataBody.append('beneficiary[birthday]', handleBirthday(formDataObj.DataNascimento))
    formDataBody.append('beneficiary[email]', formDataObj.Email)
    formDataBody.append('beneficiary[phone]', formDataObj.Celular)
    formDataBody.append('address[zip_code]', formDataObj.CEP)
    formDataBody.append('address[state]', formDataObj.estado)
    formDataBody.append('address[city]', formDataObj.cidade)
    formDataBody.append('address[neighborhood]', formDataObj.bairro)
    formDataBody.append('address[street]', formDataObj.rua)
    formDataBody.append('address[number]', formDataObj.Numero)
    formDataBody.append('address[complement]', formDataObj.Complemento)
    formDataBody.append('plan', formDataObj.plano)

    await api.post("/landing/pet", formDataBody, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
