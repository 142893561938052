import React, { useState } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export function FileInputPreview({file,setFile,name}) {

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
        toast.error('O arquivo selecionado excede 5MB. Por favor, escolha um arquivo menor.');
    } else {
      setFile(selectedFile);
    }
  };

  return (
    <div className="fileinput_field">
      <label>
        <input type="file" name={name} onChange={handleFileChange} hidden multiple required />
        Inserir arquivo
      </label>
      {file && (
        <div>
          <p>Nome do Arquivo: {file.name}</p>
          {file.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(file)} alt={`Preview ${file.name}`} style={{ maxWidth: '200px' }} />
          ) : file.type === 'application/pdf' ? (
            <embed src={URL.createObjectURL(file)} type="application/pdf" width="200" height="200" />
          ) : (
            <p>Não é possível exibir o preview deste tipo de arquivo.</p>
          )}
        </div>
      )}
    </div>
  );
}
