import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import parse from 'html-react-parser';
import 'swiper/css';
import 'swiper/css/pagination';
import Div from '../Div';
import { ArrowDown } from '../arrow_down';
import { useSolicitationStorage } from '../CheckPreviousSolicitationContext';
import { useSlideContext } from '../SlideContext';
import {basepath} from "../../App"

export default function FullScreenVerticalSlider({ data }) {

  const renderBullet = (index, className) => {
    return `<span class="nav_item ${className}">${data[index].title}</span>`;
  }

  const checkWord = (word) => {
    switch (word) {
      case 'VidaSaude':
        return 'Vida e Saude';
      case 'SeguroVida':
        return 'Seguro Vida';
      case 'SeguroRcv':
        return 'Seguro RC-V';
      case 'SeguroResidencial':
        return 'Seguro Residencial';
      case 'SeguroAP':
        return 'Seguro AP';
      case 'SeguroAPP':
        return 'Seguro APP';
      case 'BuscadeVeículos':
        return 'Busca de Veículos';
      case 'BINRF':
        return 'BIN + RF';
      case 'PesquisadeRestrições':
        return 'Pesquisa de Restrições';
      case 'PesquisadeDébito':
        return 'Pesquisa de Débito';
      case 'PesquisadeProprietáriosAnteriores':
        return 'Pesquisa de Proprietários Anteriores';
      case 'Assistência24hs':
        return 'Assistência 24hs';
      case 'CarroReserva':
        return 'Carro Reserva';
      case 'PequenosReparos':
        return 'Pequenos Reparos';
      default:
        return word;
    }
  }

  return (
    <>
      <Div className="cs-vertical_slider cs-swiper_arrow_style_1">
        <Div className="swiper-button image-swiper-button-next">
          <ArrowDown />
        </Div>
        <Swiper
          direction={'vertical'}
          slidesPerView={1}
          spaceBetween={0}
          mousewheel={true}
          allowTouchMove={window.innerWidth <= 800 ? true : false}
          pagination={{
            clickable: true,
            renderBullet: (index, className) => renderBullet(index, className)
          }}
          speed={1000}
          autoplay={false}
          loop={true}
          modules={[Mousewheel, Pagination, Navigation]}
          className="mySwiper"
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
            disabledClass: 'swiper-button-disabled',
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              {item.video ? (
                <Div
                  className={`${item.title === "Bem-vindo!" ? "homeslide_top" : ""} ${item.title === "Assistencias" ? "cs_hero_assist" : ""} cs-hero cs-style5 cs-bg"`}
                  style={{ backgroundImage: `url(${item.title === "Bem-vindo!" && window.innerWidth <= 800 ? `${basepath}/img/gallery_images/home_banner.webp` : ""})` }}
                >
                  {item.video && (
                    <div className='video_wrap'>
                      <video id="videoBG" autoPlay={true} playsInline muted loop>
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </div>
                  )}

                  <Div className="cs-hero_text">
                    { window.innerWidth <= 768 && item.title === "Bem-vindo!" ? (
                      null
                    ) : (
                      <h2 className="mb-0 cs-hero_title">{parse(item.title)}</h2>
                    )}
                    {item.description && <p>{item.description}</p>}

                    {item.extra && (
                      item.extra
                    )}

                    {item.baloons && (
                      <div className='service_baloon_wrapp d-flex gap-4 mt-3 mb-4'>
                        {item.baloons.map(baloon => {
                          if (item.menu_button) {
                            return (
                              <div key={baloon.id} className='menu_wrapper'>
                                <div className="">
                                  <input type="radio" id={`toogle${baloon.id}`} name='baloon_menu' className="hidden-trigger" />
                                  <label htmlFor={`toogle${baloon.id}`} className={`circle service_baloon ${baloon.title}`}>
                                    <span className="tooltip">{checkWord(baloon.title)}</span>
                                    <span>{baloon.icon}</span>
                                  </label>
                                  {baloon.menu && (
                                    <div className="subs">
                                      {baloon.menu.map((menu) => {
                                        return (
                                          <Link key={menu.id} className="menu_Btn sub-circle" to={menu.link}>
                                            <input
                                              value={`${menu.id}`}
                                              name="sub-circle"
                                              type="button"
                                              id={`sub${menu.id}`}
                                              className="hidden-sub-trigger"
                                            />
                                            <label className={`menu_sign menu_baloon_label`} htmlFor={`sub${menu.id}`}>
                                              {menu.icon}
                                            </label>
                                            <div className='menu_text'>{checkWord(menu.title)}</div>
                                          </Link>
                                        )
                                      })}
                                    </div>
                                  )}

                                </div>
                              </div>
                            )

                          } else {
                            return (
                              <Link key={baloon.id} className={`service_baloon ${baloon.title}`} to={baloon.link}>
                                <span class="tooltip">{checkWord(baloon.title)}</span>
                                <span>{baloon.icon}</span>
                              </Link>
                            )
                          }
                        })}
                      </div>
                    )}

                    {item.aboutButton && (
                      <Link to={item.href} className="cs-btn cs-style1 cs-type1">
                        <span>Saber mais!</span>
                      </Link>
                    )}
                  </Div>

                </Div>
              ) : (
                <Div
                  className={`${item.title === "Assistencias" ? "cs_hero_assist" : ""} cs-hero cs-style5 cs-bg"`}
                  style={{ backgroundImage: `url(${item.title === "Assistencias" && window.innerWidth <= 800 ? `${basepath}/img/gallery_images/assistencia_mobile.webp` : item.imgUrl})` }}
                >

                  <Div className="cs-hero_text">
                    <h2 className="mb-0 cs-hero_title">{parse(item.title)}</h2>
                    {item.description && <p>{item.description}</p>}


                    {item.extra && (
                      item.extra
                    )}

                    {item.baloons && (
                      <div className='service_baloon_wrapp d-flex gap-4 mt-3 mb-4'>
                        {item.baloons.map(baloon => {
                          if (item.menu_button) {
                            return (
                              <div key={baloon.id} className='menu_wrapper'>
                                <div className="">
                                  <input type="radio" id={`toogle${baloon.id}`} name='baloon_menu' className="hidden-trigger" />
                                  <label htmlFor={`toogle${baloon.id}`} className={`circle service_baloon ${baloon.title}`}>
                                    <span className="tooltip">{checkWord(baloon.title)}</span>
                                    <span>{baloon.icon}</span>
                                  </label>
                                  {baloon.menu && (
                                    <div className="subs">
                                      {baloon.menu.map((menu) => {
                                        return (
                                          <Link key={menu.id} className="menu_Btn sub-circle" to={menu.link}>
                                            <input
                                              value={`${menu.id}`}
                                              name="sub-circle"
                                              type="button"
                                              id={`sub${menu.id}`}
                                              className="hidden-sub-trigger"
                                            />
                                            <label className={`menu_sign menu_baloon_label`} htmlFor={`sub${menu.id}`}>
                                              {menu.icon}
                                            </label>
                                            <div className='menu_text'>{checkWord(menu.title)}</div>
                                          </Link>
                                        )
                                      })}
                                    </div>
                                  )}

                                </div>
                              </div>
                            )

                          } else {
                            return (
                              <Link key={baloon.id} className={`service_baloon ${baloon.title}`} to={baloon.link}>
                                <span class="tooltip">{checkWord(baloon.title)}</span>
                                <span>{baloon.icon}</span>
                              </Link>
                            )
                          }
                        })}
                      </div>
                    )}

                    {item.aboutButton && (
                      <Link to={item.href} className="cs-btn cs-style1 cs-type1">
                        <span>Saber mais!</span>
                      </Link>
                    )}
                  </Div>

                </Div>
              )}

            </SwiperSlide>
          ))}
        </Swiper>
      </Div>
    </>
  );
}
