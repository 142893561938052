import { api } from "../utils/axios";

const handleType = (type) => {
    switch (type) {
        case "Automóvel":
            return "car"
        case "Motocicleta":
            return "motorcycle"
    }
}

export async function sendRepairs(formData) {

    const formDataObj = Object.fromEntries(formData)

    const formDataBody = new FormData()

    formDataBody.append('plate', formDataObj.Placa)
    formDataBody.append('chassi', formDataObj.Chassi)
    formDataBody.append('description', formDataObj.PecaSerReparadaSinistro)
    formDataBody.append('assistance_type', formDataObj.Peca)
    formDataBody.append('company[name]', formDataObj.SolicitanteEmpresa)
    formDataBody.append('company[cnpj]', formDataObj.CNPJ.replace(/[./-]/g, ''))
    formDataBody.append('company[email]', formDataObj.EmailEmpresa)
    formDataBody.append('company[phone]', formDataObj.TelefoneEmpresa)
    formDataBody.append('beneficiary[name]', formDataObj.Nome)
    formDataBody.append('beneficiary[email]', formDataObj.Email)
    formDataBody.append('beneficiary[cpf]', formDataObj.CPF.replace(/[.-]/g, ''))
    formDataBody.append('beneficiary[phone]', formDataObj.Telefone)
    formDataBody.append('address[state]', formDataObj.Estado)
    formDataBody.append('address[city]', formDataObj.Cidade)
    formDataBody.append('vehicle[type]', handleType(formDataObj.Tipo))
    formDataBody.append('vehicle[brand]', formDataObj.Fabricante)
    formDataBody.append('vehicle[year]', formDataObj.AnoFabricacao)
    formDataBody.append('vehicle[model][year]', formDataObj.AnoModelo)
    formDataBody.append('vehicle[model][name]', formDataObj.modelo)
    formDataBody.append('attachments[0][type]', 'damaged-glass')
    formDataBody.append('attachments[0][file]', formDataObj.Anexo2)
    formDataBody.append('attachments[1][type]', 'vehicle-preliminary-inspection')
    formDataBody.append('attachments[1][file]', formDataObj.Anexo3)

    await api.post("/landing/small-repair", formDataBody, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
