import React from 'react'
import Div from '../Div'
import './funfact.scss'

const numbers = [
  {
    title: 'Usuários no Clube de Descontos',
    factNumber: '2.500.000',
  },
  {
    title: 'Diárias de carro reserva liberadas todo o mês',
    factNumber: '15.000',
  },
  {
    title: 'Novos segurados de vida todo mês',
    factNumber: '1.400',
  },
  {
    title: 'Atendimentos de assistência residencial todo mês',
    factNumber: '33.000',
  },
];

export default function FunFact({variant, title, subtitle, data}) {
  return (
    <Div className={variant ? `cs-funfact_wrap ${variant}`: 'cs-funfact_wrap'}>
      <Div className="cs-funfact_shape"  style={{backgroundImage: 'url(./images/funfact_shape_bg.svg)'}} />
      <Div className="cs-funfact_left">
        <Div className="cs-funfact_heading">
          <h2>Nossos números</h2>
          <p>Conquistar um novo associado é muito bom, fideliza-lo é ainda melhor!</p>
        </Div>
      </Div>
      <Div className="cs-funfact_right">
        <Div className="cs-funfacts">
        {numbers.map((item, index) => (
          <Div className="cs-funfact cs-style1 d-flex flex-column align-items-start text-start" key={index}>
            <Div className="cs-funfact_number cs-primary_font cs-semi_bold cs-primary_color"><span/>{item.factNumber}</Div>
            <Div className="cs-funfact_text">
              <p>{item.title}</p>
            </Div>
          </Div>
          ))}
        </Div>
      </Div>
    </Div>
  )
}
