import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import CustomCursor from '../CustomCursor';
import Footer from '../Footer';
import Header from '../Header';
import { ModalProvider, SolicitationStorageContext } from '../CheckPreviousSolicitationContext';
import { WhatsApp } from '../whatsapp';
import { SlideProvider } from '../SlideContext';

export default function Layout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header variant={headerVariant} />
      <SlideProvider>
        <SolicitationStorageContext>
          <Outlet />
        </SolicitationStorageContext>
      </SlideProvider>
    </>
  );
}
