import { Umbrella } from "phosphor-react";
import { basepath } from "../App";
import { Car, ExternalLink, FolderSearch, Gift, HeartPulse } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';

export function SideMenu() {

    const menuData = [
        {
            id: 1,
            title: "Assistencias",
            icon: <Car size={26} />,
            childrens: [
                {
                    id: 1,
                    title: "Carro",
                    childrens: [
                        {
                            id: 1,
                            title: "Pequenos Reparos",
                            href: `${basepath}/pequenosreparos`
                        }, {
                            id: 2,
                            title: "Assistencia 24hrs",
                            href: `${basepath}/assistencia24`
                        },
                        {
                            id: 3,
                            title: "Carro Reserva",
                            href: `${basepath}/carroreserva`
                        },
                        {
                            id: 4,
                            title: "Vidros",
                            href: `${basepath}/vidro`
                        },
                    ]
                },
                {
                    id: 2,
                    title: "Casa",
                    childrens: [
                        {
                            id: 1,
                            title: "Pet",
                            href: `${basepath}/pet`
                        }, {
                            id: 2,
                            title: "Residencial",
                            href: `${basepath}/residencial`
                        },
                        {
                            id: 3,
                            title: "Bike",
                            href: `${basepath}/bike`
                        }
                    ]
                },
                {
                    id: 3,
                    title: "Vida e Saude",
                    childrens: [
                        {
                            id: 1,
                            title: "Telemedicina",
                            href: `${basepath}/telemedicina`
                        }, {
                            id: 2,
                            title: "Funeral",
                            href: `${basepath}/funeral`
                        },
                        {
                            id: 3,
                            title: "Odonto",
                            href: `${basepath}/odonto`
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            title: "Seguros",
            icon: <Umbrella size={26} />,
            childrens: [
                {
                    id: 1,
                    title: "Seguro AP",
                    href: `${basepath}/seguro_ap`
                },
                {
                    id: 2,
                    title: "Seguro APP",
                    href: `${basepath}/seguro_app`
                },
                {
                    id: 3,
                    title: "Seguro Vida",
                    href: `${basepath}/seguro_de_vida`
                },
                {
                    id: 4,
                    title: "Seguro Residencial",
                    href: `${basepath}/seguro_residencial`
                },
                {
                    id: 5,
                    title: "Seguro RC-V",
                    href: `${basepath}/seguro_rcv`
                },
            ]
        },
        {
            id: 3,
            title: "Clube",
            icon: <Gift size={26} />
        },
        {
            id: 3,
            title: "BBconsulta",
            icon: <FolderSearch size={26} />,
            childrens: [
                {
                    id: 1,
                    title: "BuscaVeiculos",
                    href: `${basepath}/buscaveiculos`
                },
                {
                    id: 2,
                    title: "BinRF",
                    href: `${basepath}/binrf`
                },
                {
                    id: 3,
                    title: "Pesquica de Restricoes",
                    href: `${basepath}/restricoes`
                },
                {
                    id: 4,
                    title: "Pesquisa de Debito",
                    href: `${basepath}/debito`
                },
                {
                    id: 5,
                    title: "Pesquisa de Proprietarios Anteriores",
                    href: `${basepath}/proprietarios`
                },
            ]
        },
    ]

    const navigate = useNavigate()

    const [showAssistenciaBox, setShowAssistenciaBox] = useState(0)
    const [showSegurosBox, setShowSegurosBox] = useState(0)
    const [showBBConsultaBox, setShowBBConsultaBox] = useState(0)

    const [assistenciaActive, setAssistenciaActive] = useState(false)
    const [segurosActive, setSegurosActive] = useState(false)
    const [consultaActive, setConsultaActive] = useState(false)

    const [showAssistenciaBoxDisplay, setShowAssistenciaBoxDisplay] = useState("none")
    const [showSegurosBoxDisplay, setShowSegurosBoxDisplay] = useState("none")
    const [showBBConsultaBoxDisplay, setShowBBConsultaBoxDisplay] = useState("none")

    function handleSideMenu(event,box) {

        switch (box) {
            case "Assistencias": {
                if (showAssistenciaBox === 1) {
                    setShowAssistenciaBox(0)
                    setShowAssistenciaBoxDisplay("none")
                    setAssistenciaActive(false)
                    
                } else {
                    setShowSegurosBox(0)
                    setShowSegurosBoxDisplay("none")
                    setShowBBConsultaBox(0)
                    setShowBBConsultaBoxDisplay("none")
                    setShowAssistenciaBox(1)
                    setShowAssistenciaBoxDisplay("flex")
                    setAssistenciaActive(true)
                    setSegurosActive(false)
                    setConsultaActive(false)
                }
                break;
            }
            case "Seguros": {
                if (showSegurosBox === 1) {
                    setShowSegurosBox(0)
                    setShowSegurosBoxDisplay("none")
                    setSegurosActive(false)
                } else {
                    setShowAssistenciaBox(0)
                    setShowAssistenciaBoxDisplay("none")
                    setShowBBConsultaBox(0)
                    setShowBBConsultaBoxDisplay("none")
                    setShowSegurosBox(1)
                    setShowSegurosBoxDisplay("grid")
                    setAssistenciaActive(false)
                    setSegurosActive(true)
                    setConsultaActive(false)
                }
                break;
            }
            case "Clube": {
                navigate(`${basepath}/clube`)
                break;
            }
            case "BBconsulta": {
                if (showBBConsultaBox === 1) {
                    setShowBBConsultaBox(0)
                    setShowBBConsultaBoxDisplay("none")
                    setConsultaActive(false)
                } else {
                    setShowSegurosBox(0)
                    setShowSegurosBoxDisplay("none")
                    setShowAssistenciaBox(0)
                    setShowAssistenciaBoxDisplay("none")
                    setShowBBConsultaBox(1)
                    setShowBBConsultaBoxDisplay("grid")
                    setAssistenciaActive(false)
                    setSegurosActive(false)
                    setConsultaActive(true)
                }
                break;
            }
            default: {
                console.log("Algo deu errado durante a selecao do menu");
              }
        }
    }

    const resetAll = () => {
        setShowAssistenciaBox(0)
        setShowSegurosBox(0)
        setShowBBConsultaBox(0)
        setShowAssistenciaBoxDisplay("none")
        setShowSegurosBoxDisplay("none")
        setShowBBConsultaBoxDisplay("none")
    }

    const itemOpacity = (item) => {
        switch (item) {
            case "Assistencias": {
                return showAssistenciaBox
            }
            case "Seguros": {
                return showSegurosBox
            }
            case "BBconsulta": {
                return showBBConsultaBox
            }
            default: {
                console.log("Algo deu errado durante a selecao do menu");
              } 
        }
    }

    const itemDisplay = (item) => {
        switch (item) {
            case "Assistencias": {
                return showAssistenciaBoxDisplay
            }
            case "Seguros": {
                return showSegurosBoxDisplay
            }
            case "BBconsulta": {
                return showBBConsultaBoxDisplay
            }
            default: {
                console.log("Algo deu errado durante a selecao do menu");
              } 
        }
    }

    const handleMenuActive = (item) => {
        switch (item) {
            case "Assistencias": {
                return assistenciaActive
            }
            case "Seguros": {
                return segurosActive
            }
            case "BBconsulta": {
                return consultaActive
            }
            default: {
                return;
              } 
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.classList.contains('menu_content')) {
            resetAll()
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <nav className="menu_content sidebar_nav">
            <a href={basepath === "" ? "/" : basepath}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 278 206" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M147.178 75.7281C149.838 79.1403 152.431 83.1435 154.821 87.9428C155.832 89.9735 157.323 93.7738 158.372 96.4746L161.508 103.421C161.7 103.749 162.175 104.37 162.338 104.63C162.502 104.891 162.876 105.457 163.204 105.821C190.59 69.6582 231.135 88.018 238.438 115.252C248.348 152.209 205.66 184.848 174.568 159.099C146.223 135.623 169.6 107.428 136.523 71.9243C108.114 41.4312 47.7694 43.9321 44.3535 59.3933L44.434 103.13C56.6604 89.338 79.4006 79.4179 100.419 91.913C124.781 106.396 114.306 122.388 123.957 151.075C146.325 217.564 239.903 225.383 270.755 158.807C280.341 138.123 279.512 111.851 269.849 92.1032C261.317 74.6649 245.197 60.515 225.939 53.1692C195.033 41.7018 160.114 51.369 147.18 75.728H147.179L147.178 75.7281Z" fill="#E33E2B" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M134.117 182.222C130.833 177.558 126.955 172.53 123.465 165.781C120.375 159.808 118.011 151.685 114.943 146.589C92.0052 179.539 37.6833 177.995 37.8835 113.912C38.0139 72.1612 49.7667 -3.69681 0.20321 0.891921C0.368767 30.4723 0.221685 60.0939 0.221685 89.6789C0.221685 120.038 -2.63603 144.122 12.1691 167.996C41.4964 215.29 105.007 213.866 134.117 182.222L134.117 182.222Z" fill="#0987FF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M147.18 75.728C146.392 40.6725 155.34 3.6695 120 0L120.001 49.2978C123.615 57.5258 136 61.3854 147.18 75.728Z" fill="#FFCC29" />
                </svg>
            </a>
            <ul className=" links_box">
                {menuData.map(menuItem => (
                    <>
                        <li>
                            <button
                                className={`menu_content ${handleMenuActive(menuItem.title) ? "active ": ""}`}
                                title={menuItem.title}
                                type="button"
                                onClick={(e) => handleSideMenu(e,menuItem.title)}
                                data-tooltip-id={`tooltip-${menuItem.title}`}
                                data-tooltip-content={menuItem.title}
                            >
                                {menuItem.icon}
                            </button>
                            <Tooltip id={`tooltip-${menuItem.title}`} place="right" effect="solid" />
                        </li>
                        {menuItem.childrens && (
                            <div class={`menu_content sidemenu_intern_box ${menuItem.title}`} style={{ opacity: itemOpacity(menuItem.title), display: itemDisplay(menuItem.title) }}>
                                {menuItem.childrens.map(children => (
                                    <article key={children.id}>
                                        {children.childrens ? <h5>{children.title}</h5>  :  <span></span> }
                                        <ul className="internBoxLinks">
                                            {children.childrens ? (
                                                <>
                                                    {
                                                        children.childrens.map(item => (
                                                            <li key={item.id}>
                                                                <Link className="d-flex align-items-center justify-content-between" to={item.href} >
                                                                    <span>{item.title}</span>
                                                                    <ExternalLink size={14} />
                                                                </Link>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <li key={children.id}>
                                                    <Link className="d-flex align-items-center justify-content-between" to={children.href} >
                                                        <span>{children.title}</span>
                                                        <ExternalLink size={14} />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </article>
                                ))}
                            </div>
                        )}

                    </>
                ))}

            </ul>
            <a className="wpp_menu wpp_m" href="https://api.whatsapp.com/send/?phone=31992790202&text=Ol%C3%A1%2C+como+vai%3F&type=phone_number&app_absent=0" target="_blank">
                <img src="https://imagepng.org/wp-content/uploads/2017/08/WhatsApp-icone.png" width={24} alt="" />
                <span class="tooltip">Fale conosco no WhatsApp</span>
            </a>
        </nav>
    )
}