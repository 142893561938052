import Div from "../Div";
import Spacing from "../Spacing";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CheckCircle } from "phosphor-react";
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext";
import { FileInputPreview } from "./FileInputPreview";
import { Loading } from "../loading";
import { Search } from "lucide-react";
import { sendPet } from "../../api/send-pet";


export function PetForm() {

    const { keepLastPet } = useSolicitationStorage()
    const [sendingButton, setSendingButton] = useState("Solicitar benefício")
    const [petStatus, setpetStatus] = useState(null)
    const [isSearchingCep, setIsSearchingCep] = useState(false)

    const [petsolicitantenome, setpetsolicitantenome] = useState(null)
    const [petsolicitantecpf, setpetsolicitantecpf] = useState(null)
    const [petsolicitantenascimento, setpetsolicitantenascimento] = useState(null)
    const [petsolicitanteemail, setpetsolicitanteemail] = useState(null)
    const [petsolicitantecelular, setpetsolicitantecelular] = useState(null)

    const [petsolicitantecep, setpetsolicitantecep] = useState(null)
    const [petsolicitantelogradouro, setpetsolicitantelogradouro] = useState(null)
    const [petsolicitantebairro, setpetsolicitantebairro] = useState(null)
    const [petsolicitanteestado, setpetsolicitanteestado] = useState(null)
    const [petsolicitantecidade, setpetsolicitantecidade] = useState(null)
    const [petsolicitantenumero, setpetsolicitantenumero] = useState(null)
    const [petsolicitantecomplemento, setpetsolicitantecomplemento] = useState(null)
    const [petsolicitanteplano, setpetsolicitanteplano] = useState(null)

    const handleCEP = (target) => {
        if (target.value.length != 9 && target.value.length != 0) {
            // toast.error("Campo CEP invalido.")
        } else {
            setIsSearchingCep(true)
            fetch(`https://nvo.brasilbeneficios.club/api/get-addr/${target.value.replace(/-/g, '')}`)
                .then((res) => res.json())
                .then((data) => {
                    const response = data.data
                    setpetsolicitantecep(target.value)
                    setpetsolicitantelogradouro(response.rua)
                    setpetsolicitantebairro(response.bairro)
                    setpetsolicitantecidade(response.cidade)
                    setpetsolicitanteestado(response.estado)
                })
                .catch(err => toast.error("Existe algo errado com o CEP."))
                .finally(() => setIsSearchingCep(false))
        }
    }

    const handlePetSubmit = async (event) => {
        event.preventDefault();
        setSendingButton("Enviando...")

        const formData = new FormData(event.currentTarget);

        await sendPet(formData)

        fetch("https://brasilbeneficios.club/bb/area-cliente/pet/send", {
            method: "POST",
            mode: "no-cors", 
            credentials: "same-origin", 
            body: formData
        })
            .then((res) => {

                toast.success("Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!")

                for (const data of formData.entries()) {
                    localStorage.setItem(data[0], data[1])
                }

                setpetStatus("Sended")
                localStorage.setItem("lastSolicitatedService", "Pet")

            })


        setTimeout(() => {
            setSendingButton("Solicitar benefício")
        }, 2000);
    }

    return (
        <>
            {petStatus === null ?
                <Div className="fade-in col-lg-8">
                    {keepLastPet ? (
                        <form onSubmit={handlePetSubmit} method="post" encType="multipart/form-data">
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Nome:</label>
                                    <input type="text" onChange={(e) => setpetsolicitantenome(e.target.value)} value={localStorage.getItem("Nome")} name="Nome" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">CPF:</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=""
                                        placeholder="___.___.___-__"
                                        onChange={(e) => setpetsolicitantecpf(e.target.value)}
                                        value={localStorage.getItem("CPF")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="cpf" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Data de Nascimento:</label>
                                    <InputMask
                                        mask="99/99/9999"
                                        maskChar=""
                                        placeholder="__/__/____"
                                        onChange={(e) => setpetsolicitantenascimento(e.target.value)}
                                        value={localStorage.getItem("DataNascimento")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="DataNascimento" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail:</label>
                                    <input type="email" onChange={(e) => setpetsolicitanteemail(e.target.value)} name="Email" value={localStorage.getItem("Email")} className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Celular:</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setpetsolicitantecelular(e.target.value)}
                                        value={localStorage.getItem("Celular")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="Celular" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>


                            <Div className="row">
                                <Div className="col-lg-4">
                                    <label className="form_field_label">CEP:</label>
                                    <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        placeholder="_____-___"
                                        onChange={(e) => setpetsolicitantecep(e.target.value)}
                                        onBlur={handleCEP}
                                        value={localStorage.getItem("CEP")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                    <button className="searchButton" onClick={handleCEP} disabled={isSearchingCep ? true : false} type="button">
                                        {isSearchingCep ? <Loading /> : <Search size={20} />}
                                    </button>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>

                            <Div className="row fade-in">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Logradouro:</label>
                                    <input type="text" value={localStorage.getItem("Logradouro")} name="rua" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Bairro:</label>
                                    <input type="text" value={localStorage.getItem("Bairro")} name="bairro" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Cidade:</label>
                                    <input type="text" value={localStorage.getItem("Cidade")} name="cidade" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Estado:</label>
                                    <input type="text" value={localStorage.getItem("Estado")} name="estado" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Numero:</label>
                                    <input type="text" onChange={(e) => setpetsolicitantenumero(e.target.value)} value={localStorage.getItem("Numero")} name="Numero" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Complemento:</label>
                                    <input type="text" onChange={(e) => setpetsolicitantecomplemento(e.target.value)} name="Complemento" value={localStorage.getItem("Complemento")} className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Plano:</label>
                                    <select className="cs-form_field" name="plano" onChange={(e) => setpetsolicitanteplano(e.target.value)}>
                                        <option value="">Selecione uma opcao</option>
                                        <option value="Plano A" selected={localStorage.getItem("Plano") === "Plano A" ? true : false} >Plano A</option>
                                    </select>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>


                            <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                <span>{sendingButton}</span>
                            </button>

                        </form>
                    ) : (
                        <form onSubmit={handlePetSubmit} method="post" encType="multipart/form-data">
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Nome:</label>
                                    <input type="text" onChange={(e) => setpetsolicitantenome(e.target.value)} value={petsolicitantenome ?? ""} name="Nome" className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">CPF:</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=""
                                        placeholder="___.___.___-__"
                                        onChange={(e) => setpetsolicitantecpf(e.target.value)}
                                        value={petsolicitantecpf ?? ""}
                                    >
                                        {(inputProps) => <input {...inputProps} name="cpf" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Data de Nascimento:</label>
                                    <InputMask
                                        mask="99/99/9999"
                                        maskChar=""
                                        placeholder="__/__/____"
                                        onChange={(e) => setpetsolicitantenascimento(e.target.value)}
                                        value={petsolicitantenascimento ?? ""}
                                    >
                                        {(inputProps) => <input {...inputProps} name="DataNascimento" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail:</label>
                                    <input type="email" onChange={(e) => setpetsolicitanteemail(e.target.value)} name="Email" value={petsolicitanteemail ?? ""} className="cs-form_field" />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Celular:</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setpetsolicitantecelular(e.target.value)}
                                        value={petsolicitantecelular ?? ""}
                                    >
                                        {(inputProps) => <input {...inputProps} name="Celular" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>


                            <Div className="row">
                                <Div className="col-lg-4">
                                    <label className="form_field_label">CEP:</label>
                                    <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        placeholder="_____-___"
                                        onChange={(e) => handleCEP(e.target)}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                    <button className="searchButton" onClick={handleCEP} disabled={isSearchingCep ? true : false} type="button">
                                        {isSearchingCep ? <Loading /> : <Search size={20} />}
                                    </button>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>

                            {petsolicitantelogradouro && (
                                <Div className="row fade-in">
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Logradouro:</label>
                                        <input type="text" value={petsolicitantelogradouro} name="rua" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Bairro:</label>
                                        <input type="text" value={petsolicitantebairro} name="bairro" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Cidade:</label>
                                        <input type="text" value={petsolicitantecidade} name="cidade" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Estado:</label>
                                        <input type="text" value={petsolicitanteestado} name="estado" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Numero:</label>
                                        <input type="text" onChange={(e) => setpetsolicitantenumero(e.target.value)} name="Numero" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Complemento:</label>
                                        <input type="text" onChange={(e) => setpetsolicitantecomplemento(e.target.value)} name="Complemento" className="cs-form_field" />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                </Div>
                            )}

                            {petsolicitantenumero && (
                                <Div className="row">
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Plano:</label>
                                        <select className="cs-form_field" name="plano" onChange={(e) => setpetsolicitanteplano(e.target.value)}>
                                            <option value="">Selecione uma opcao</option>
                                            <option value="A">Plano A</option>
                                        </select>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                </Div>
                            )}

                            {petsolicitanteplano && (
                                <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                    <span>{sendingButton}</span>
                                </button>
                            )}
                        </form>
                    )}

                </Div>
                :
                <Div>
                    <CheckCircle size={50} />
                    <h5 className="mt-3">Sucesso!</h5>
                    <p>Sua solicitação foi realizada com sucesso!<br />Em até 48 horas entraremos em contato para fazer a liberação do seu benefício!</p>
                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={(e) => window.location.reload()}>
                        <span>Realizar outra solicitacao</span>
                    </button>
                </Div>}
        </>
    )
}