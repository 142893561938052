import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { BadgeDollarSign, BadgePercent, Gift, Pill, ShoppingBasket } from 'lucide-react';
import { SideMenu } from '../SideMenu';
import { ClubeForm } from '../ClubeForm';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function ClubeBeneficios() {
  pageTitle('Clube de Beneficios');

  const funfaceData = [
    {
      title: 'Usuários no Clube de Descontos',
      factNumber: '2.5M',
    },
    {
      title: 'Diárias de carro reserva liberadas todo o mês',
      factNumber: '15K',
    },
    {
      title: 'Novos segurados de vida todo mês',
      factNumber: '1.4K',
    },
    {
      title: 'Atendimentos de assistência residencial todo mês',
      factNumber: '33K',
    },
  ];

  const logos = [
    {
      id: 1,
      logo: `${basepath}/img/parceiros/cinemark_logo.svg`
    },
    {
      id: 2,
      logo: `${basepath}/img/parceiros/dominos_logo.svg`
    },
    {
      id: 3,
      logo: `${basepath}/img/parceiros/estacio_logo.svg`
    },
    {
      id: 4,
      logo: `${basepath}/img/parceiros/magalu_logo.svg`
    },
    {
      id: 5,
      logo: `${basepath}/img/parceiros/marisa_logo.svg`
    },
    {
      id: 6,
      logo: `${basepath}/img/parceiros/netshoes_logo.svg`
    },
  ]

  const PRitems = [
    {
      id: 1,
      img: `https://t4.ftcdn.net/jpg/03/16/36/11/240_F_316361168_gfjK46dx4NUgEuNYSypyh8FtTv665Ftb.jpg`,
      title: "Descubra informações valiosas",
      description: "Hábitos de consumo e utilização das vantagens em tempo real. Com isso, você pode aumentar as vantagens que realmente interessam às pessoas."
    },
    {
      id: 2,
      img: `https://t3.ftcdn.net/jpg/03/22/17/44/240_F_322174411_9m6IAm4mEwEEVmBb1O0GjTn2RGPLHt1G.jpg`,
      title: "Customize a plataforma do seu jeito",
      description: "Personalize cores, logos, imagens e texto. Somos invisíveis para que as pessoas tenham todas as vantagens oferecidas com a sua marca."
    },
    {
      id: 3,
      img: `https://t3.ftcdn.net/jpg/01/27/80/44/240_F_127804475_HacnplIpXArNdsdGAHx31ho67igowhe3.jpg`,
      title: "Faça uma integração completa com o seu produto",
      description: "Quer oferecer uma experiência totalmente integrada ao seu produto? Através da nossa API, você utiliza o nosso motor de vantagens do jeito que quiser e imaginar."
    },
  ]

  const promoItems = [
    {
      id: 1,
      icon: <BadgePercent color="#FF4A17" size={40} />,
      title: "Descontos",
      description: "Descontos incríveis nas melhores marcas do Brasil"
    },
    {
      id: 2,
      icon: <BadgeDollarSign color="#FF4A17" size={40} />,
      title: "Cashback",
      description: "Dinheiro de volta direto na conta-corrente"
    },
    {
      id: 3,
      icon: <Pill color="#FF4A17" size={40} />,
      title: "Vida",
      description: "Medicamentos com até 40% de deconto nas principais farmácias do país"
    },
    {
      id: 4,
      icon: <Gift color="#FF4A17" size={40} />,
      title: "Cards",
      description: "Compra de gift card com cashback exclusivo"
    },
    {
      id: 5,
      icon: <ShoppingBasket color="#FF4A17" size={40} />,
      title: "Store",
      description: "Vitrine de produtos com preços exclusivos para todos os perfis de consumidores"
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Clube de Beneficios"
        bgSrc={`${basepath}/img/gallery_images/homebanner_5.webp`}
        pageLinkText="Clube de Beneficios"
      />

      <SideMenu />
      <ToastContainer />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Monte seu clube de vantagens em poucos minutos"
              subtitle="Sobre o clube"
            >
              <Spacing lg="30" md="30" />
              <p className='text-justify'>Junte-se a mais de 03 milhões de pessoas e fidelize seus colaboradores, clientes e associados com a maior plataforma de vantagens do Brasil.</p>
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Link to="https://clubebb.com/" target='_blank'>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                  <span>Acessar Clube</span>
                </button>
              </Link>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 pt-5 offset-xl-2">
            <div className='row'>
              {logos.map(logo => {
                return (
                  <div className='col-6'>
                    <img className='mb-5' width={150} src={logo.logo} alt="" />
                  </div>
                )
              })}
            </div>
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <h2 className="text-center cs-section_title mb-5">Serviços de qualidade, ampla cobertura e melhor custo-benefício.</h2>
        <Div className="row justify-content-center mt-5 pt-5">
          {PRitems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo buttonText="Acessar Clube" cardLink="https://clubebb.com/" data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <h3 className="text-center text-secondary cs-section_subtitle">Temos diversas possibilidades para o seu negócio</h3>
        <h2 className="text-center cs-section_title mb-5">Ofereça vantagens como você quiser</h2>
        <Div className="row justify-content-center mt-5 pt-5">
          {promoItems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <div className='text-center d-flex flex-column align-items-center gap-3'>
                {item.icon}
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Conquistar um novo associado é muito bom,
          fidelizá-lo é muito melhor!"
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="text-justify cs-m0">Empresa especializada em oferecer serviços assistenciais e programa de benefícios para pessoas, empresas e Entidades de Autogestão no Brasil.</p>
              <Spacing lg="15" md="15" />
              <p className="text-justify cs-m0">Nossa missão é tornar a vida mais fácil, oferecendo serviços de excelência agregado aos nossos valores. "Não se preocupe, seu problema está em nossas mãos.</p>
              <Spacing lg="30" md="30" />
              <p><strong>Missão: </strong>Nossa missão é tornar a vida mais fácil, oferecendo serviços de excelência agregado aos nossos valores.</p>
              <p><strong>Visão: </strong>Ser a empresa de benefícios mais amada do Brasil</p>
              <p><strong>Nossos valores: </strong>Honestidade: Agimos com coerência e verdade, Inovação: Gerar e implementar novas ideias, Lealdade: Manter a lealdade à empresa e seus interesses, Responsabilidade: Aceitamos as consequências de nossas ações, Confiabilidade: Mantemos sempre nossas promessas</p>
              <Div className="text-justify cs-separator cs-accent_bg"></Div>
              <Div className="text-justify cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Div className="container">
        <Div className="row justify-content-center">
          <Div className="col-md-8">
            <Spacing lg='30' md='30' />
            <h2 className="cs-font_50 cs-m0 text-center">Sua entidade não possui um clube de benefícios?</h2>
            <Spacing lg='5' md='5' />
            <p className="cs-m0 text-center">Nos envie seu contato e em breve uma equipe especializada entrará em contato.</p>
            <Spacing lg='40' md='30' />
            <ClubeForm />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
          
          bgSrc={`${basepath}/img/_1.webp`}
        />
      </Div>

      <Footer />
    </>
  );
}
