import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { BadgePercent, Wrench } from 'lucide-react';
import { SideMenu } from '../SideMenu';
import { ToastContainer } from 'react-toastify';
import * as Dialog from '@radix-ui/react-dialog';
import { SendQalForm } from '../send-qal-form';
import { Faq } from '../Faq/faq';

export default function Rcv() {
    pageTitle('Seguro RC-V');

    const funfaceData = [
        {
            title: 'Usuários no Clube de Descontos',
            factNumber: '2.5M',
        },
        {
            title: 'Diárias de carro reserva liberadas todo o mês',
            factNumber: '15K',
        },
        {
            title: 'Novos segurados de vida todo mês',
            factNumber: '1.4K',
        },
        {
            title: 'Atendimentos de assistência residencial todo mês',
            factNumber: '33K',
        },
    ];

    const logos = [
        {
            id: 1,
            logo: `${basepath}/img/parceiros/cinemark_logo.svg`
        },
        {
            id: 2,
            logo: `${basepath}/img/parceiros/dominos_logo.svg`
        },
        {
            id: 3,
            logo: `${basepath}/img/parceiros/estacio_logo.svg`
        },
        {
            id: 4,
            logo: `${basepath}/img/parceiros/magalu_logo.svg`
        },
        {
            id: 5,
            logo: `${basepath}/img/parceiros/marisa_logo.svg`
        },
        {
            id: 6,
            logo: `${basepath}/img/parceiros/netshoes_logo.svg`
        },
    ]

    const promoItems = [
        {
            id: 1,
            icon: <BadgePercent color="#FF4A17" size={40} />,
            title: "Como é calculado o custo?",
            description: "O custo do seguro de Responsabilidade Civil Veículos é calculado por tipo de viagem."
        },
        {
            id: 2,
            icon: <Wrench color="#FF4A17" size={40} />,
            title: "O que fazer para acionar um sinistro?",
            description: "Entre em contato com a Kovr pelo telefone: 0800 000 8785, que está disponível 24h por dia, nos 7 dias da semana."
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageHeading
                title="Seguro RC-V"
                bgSrc={`${basepath}/images/rcv1.png`}
                pageLinkText="Seguro RC-V"
            />

            <SideMenu />
            <ToastContainer />
            <Spacing lg="150" md="80" />
            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Conheça mais sobre o RC-V"
                            subtitle="O que é"
                        >
                            <Spacing lg="30" md="30" />
                            <p className='text-justify'>
                                O seguro RC-V da KOVR tem faturamento mensal, considerando as averbações enviadas via AT&M. A apólice define um custo por viagem, de acordo com:
                            </p>
                            <ul>
                                <li>Transporte dentro do mesmo estado</li>
                                <li>Transporte entre estados da mesma região</li>
                                <li>Transporte entre estados de regiões diferentes</li>
                            </ul>
                            <Div className="mt-3 cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                            <div className='d-flex align-items-center gap-4'>
                                <a href={`${basepath}/CG_RC_Veicular.pdf`} download>
                                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                                        <span>Condições Gerais</span>
                                    </button>
                                </a>
                                <Dialog.Root>
                                    <Dialog.Trigger className="fade-in cs-btn cs-style1 cs-type1">Preencher Formulário QAR</Dialog.Trigger>
                                    <Dialog.Portal>
                                        <Dialog.Overlay className="fixed inset-0 z-[20000] bg-black opacity-60" />
                                        <Dialog.Content className="fixed bg-black p-6 z-[20001] rounded-md shadow-md w-full md:w-1/2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                                            <Dialog.Title className="text-lg font-bold">Formulário QAR</Dialog.Title>
                                            <Dialog.Description className="text-sm">Preencha o formulário a seguir.</Dialog.Description>

                                           <SendQalForm />
                                            
                                        </Dialog.Content>
                                    </Dialog.Portal>
                                </Dialog.Root>
                            </div>
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 pt-5 offset-xl-2">
                        <img className='rounded' src={`${basepath}/images/rcv2.png`} alt="Seguro RC-V" />
                        <Spacing lg="25" md="25" />
                    </Div>
                </Div>
            </Div>

            <Div className="container flex flex-col items-center">
                <Div className="row justify-content-center mt-5 pt-5">
                    {promoItems.map(item => (
                        <Div key={item.id} className="col-md-4 mb-5">
                            <div className='text-center d-flex flex-column align-items-center gap-3'>
                                {item.icon}
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        </Div>
                    ))}
                </Div>
            </Div>


            <Div className="container flex flex-col items-center">
                {/* <h2 className="text-center h2 cs-section_title mb-4">Perguntas Frequentes</h2> */}
                <div>
                    <Faq />
                </div>
            </Div>

            <Spacing lg="150" md="80" />
            <Div className="container">
                <Cta
                    title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
                    btnText="Solicitar serviço!"

                    bgSrc={`${basepath}/img/_1.webp`}
                />
            </Div>

            <Footer />
        </>
    );
}
