import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import './styles.css';
import { questions } from '../../utils/consts';

export const Faq = () => (
  <Accordion.Root className="AccordionRoot w-full" type="single" defaultValue="1" collapsible>

    {questions.map(question => {
      return (
        <Accordion.Item className="AccordionItem" value={question.id}>
          <AccordionTrigger>{question.question}</AccordionTrigger>
          <AccordionContent>{question.answer}</AccordionContent>
        </Accordion.Item>
      )
    })}

  </Accordion.Root>
);

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger
      className={classNames('AccordionTrigger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className="AccordionChevron" aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('AccordionContent', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
));