import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { SideMenu } from '../SideMenu';
import { Link } from 'react-router-dom';

export default function Restricoes() {
  pageTitle('Pesquisa de Restrições');

  const funfaceData = [
    {
      title: 'Numeros',
      factNumber: '40K',
    },
    {
      title: 'Numeros',
      factNumber: '50K',
    },
    {
      title: 'Numeros',
      factNumber: '245',
    },
    {
      title: 'Numeros',
      factNumber: '550',
    },
  ];

  const Ritems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_3.webp`,
      title: "Detecção de Origens Suspeitas",
      description: "Restrições financeiras e judiciais ajudam a identificar se o veículo em questão possui histórico de transações duvidosas ou está envolvido em litígios. Isso é crucial para detectar possíveis origens suspeitas que poderiam indicar ativida- des fraudulentas."
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_1.webp`,
      title: "Evitar Transferências Irregulares",
      description: "Restrições financeiras e judiciais são indicadores essenciais para evitar transfe- rências irregulares de propriedade. Isso impede que veículos associados a pro- cessos judiciais ou questões financeiras sejam transferidos de maneira indevi- da, reduzindo o risco de fraudes."
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_2.webp`,
      title: "Garantia de Regularidade Tributária",
      description: "Verificações tributárias são importantes para assegurar que o veículo não possui pendências fiscais significativas. Isso contribui para evitar a aquisição de veículos que possam gerar complicações tributárias futuras, proporcionando transações mais seguras."
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_5.webp`,
      title: "Prevenção contra Pendências Administrativas",
      description: "A comunicação de venda é um aspecto administrativo crítico. Garantir que essa comunicação seja realizada corretamente ajuda a evitar pendências admi- nistrativas que poderiam resultar em complicações legais e administrativas para os futuros proprietários do veículo."
    },
    {
      id: 5,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_4.webp`,
      title: "Redução de Riscos Financeiros",
      description: "A identificação de restrições financeiras é vital para a redução de riscos asso- ciados a transações fraudulentas. Essas restrições podem incluir financiamen- tos pendentes, evitando que veículos sob esse tipo de obrigação sejam envolvi- dos em atividades fraudulentas."
    },
    {
      id: 6,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_6.webp`,
      title: "Aumento da Confiança nas Transações",
      description: "Ao realizar verificações abrangentes de restrições, a plataforma aumenta a con- fiança nas transações. Isso é especialmente relevante para associações de pro- teção veicular, que podem garantir a integridade dos veículos antes de proc- der com processos de indenização."
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <PageHeading
        title="Pesquisa de Restrições"
        bgSrc={`${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_2.webp`}
        pageLinkText="Pesquisa de Restrições"
      />

      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7 mb-3">
            <SectionHeading
              title="Abrangendo aspectos financeiros"
              subtitle="Sobre o seguro"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">A inclusão e análise de restrições do veículo, abrangendo aspectos financeiros, judiciais, tributários e administrativos, são fundamentais para evitar fraudes e promover a integridade nos processos de indenização. Aqui estão algumas razões para a importância desses itens na prevenção de fraudes.</p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Link to="http://www.bbautoconsulta.com.br/" target='_blank'>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                  <span>Realizar Consulta</span>
                </button>
              </Link>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2 mb-3">
            <img
              src={`${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_6.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />

      <Div className="container">
        <Div className="row justify-content-center mt-5 pt-5">
          {Ritems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
