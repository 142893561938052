import { useForm } from 'react-hook-form';
import Div from '../components/Div';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export function ClubeForm() {

    const { register, handleSubmit } = useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function sendEmailAPI(body) {
        await axios.post(
          "https://apiemail.devandrew.com.br/sendEmail",
          JSON.stringify({
            destinatario: "enrico.neto@brasilatuarial.com.br",
            assunto: "Formulário Clube de Benefícios, Site Brasil Benefífios",
            titulo: "Dados preenchidos:",
            campos: [
              {
                key: "nome",
                value: body.nome,
              },
              {
                key: "email",
                value: body.email,
              },
              {
                key: "telefone",
                value: body.telefone,
              },
              {
                key: "mensagem",
                value: body.mensagem,
              },
            ],
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

    async function sendClubeRequest(data) {
        setIsSubmitting(true)
        try {
            await sendEmailAPI(data)
            toast.success("Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!")
        } catch (err) {
            toast.error("Algo deu errado! Tente novamente mais tarde ou entre em contato conosco.")
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(sendClubeRequest)} className="row">
            <Div className="col-lg-6">
                <label>Nome completo*</label>
                <input type="text" {...register("nome")} className="cs-form_field" required />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
            <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" {...register("email")} className="cs-form_field" required />
                <Div className="cs-height_20 cs-height_lg_20" />
            </Div>
            <Div className="col-lg-12">
                <label>Telefone*</label>
                <input type="text" {...register("telefone")} className="cs-form_field" required />
                <Div className="cs-height_20 cs-height_lg_20" />
            </Div>
            <Div className="col-lg-12">
                <label>Mensagem*</label>
                <textarea cols={30} rows={7} {...register("mensagem")} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
            </Div>
            <Div className="col-lg-12 d-flex justify-content-center">
                <button className="cs-btn cs-style1" type='submit' disabled={isSubmitting}>
                    <span>{isSubmitting ? "Enviando..." : "Enviar"}</span>
                    <Icon icon="bi:arrow-right" />
                </button>
            </Div>
        </form>
    )
}