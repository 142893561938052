import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { SideMenu } from '../SideMenu';
import { Link } from 'react-router-dom';

export default function Binrf() {
  pageTitle('Bin + RF');

  const funfaceData = [
    {
      title: 'Numeros',
      factNumber: '40K',
    },
    {
      title: 'Numeros',
      factNumber: '50K',
    },
    {
      title: 'Numeros',
      factNumber: '245',
    },
    {
      title: 'Numeros',
      factNumber: '550',
    },
  ];

  const BINitems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_1.webp`,
      title: "Consulta Bin",
      description: "Através da nossa integração com a base Bin, oferecemos uma análise detalhada das características do veículo. Isso inclui informações cruciais, como motor, chassi e outros itens de verificação essenciais para garantir a precisão na identi- ficação do veículo recuperado."
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_4.webp`,
      title: "Consulta RF (Registro de Furto)",
      description: "Reconhecemos a possibilidade de que equipes fraudulentas possam tentar utili- zar veículos para aplicar golpes. Ao fornecer um histórico completo de comuni- cados de roubo, nossa plataforma ajuda a prevenir essas atividades fraudulen- tas, garantindo que as associações de proteção veicular estejam cientes de qual- quer padrão suspeito ou comportamento irregular."
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_3.webp`,
      title: "Análise Abrangente das Características do Veículo",
      description: "Combinando os resultados das consultas Bin e RF, nossa plataforma oferece uma análise abrangente das características do veículo. Isso inclui detalhes espe- cíficos do motor, número do chassi e outros elementos distintivos que ajudam a confirmar a identidade do veículo recuperado."
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_5.webp`,
      title: "Verificação Prévia Antes da Indenização",
      description: "Antes de proceder com a indenização, a nossa plataforma realiza uma verifica- ção prévia minuciosa com base nas informações obtidas das consultas Bin e RF. Isso garante que a associação de proteção veicular tenha dados confiáveis sobre o veículo antes de efetuar qualquer pagamento."
    },
    {
      id: 5,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_6.webp`,
      title: "Redução de Riscos e Fraudes",
      description: "Ao analisar detalhadamente as características do veículo, nossa plataforma con- tribui significativamente para a redução de riscos e prevenção contra fraudes. A verificação rigorosa ajuda a assegurar que apenas veículos autênticos e legíti- mos sejam considerados para indenização."
    },
    {
      id: 6,
      img: `${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_7.webp`,
      title: "Relatórios Detalhados de Verificação",
      description: "A Auto Cred Car fornece relatórios detalhados que consolidam os resultados das consultas Bin e RF. Esses relatórios são valiosos para a tomada de decisões informadas, permitindo que as associações de proteção veicular tenham uma compreensão clara das características do veículo recuperado. Ao incorporar a Consulta Bin + RF em nossa plataforma, estamos comprometidos em fornecer um serviço de recuperação veicular que não apenas localiza os veículos, mas também valida suas características essenciais, promovendo a segurança e a inte- gridade nos processos de indenização."
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Bin + RF"
        bgSrc={`${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_7.webp`}
        pageLinkText="Bin + RF"
      />

      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7 mb-3">
            <SectionHeading
              title="Pesquisa veicular Bin + Rf"
              subtitle="Busca de véiculos"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Link to="http://www.bbautoconsulta.com.br/" target='_blank'>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                  <span>Realizar Consulta</span>
                </button>
              </Link>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2 mb-3">
            <img
              src={`${basepath}/img/gallery_images/bbautoconsulta/binrf/binrf_2.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />

      <Div className="container">
        <h2 className="text-center cs-section_title mb-5">Oferecemos uma solução inovadora e tecnologicamente avançada para maximizar a recuperação de veículos roubados ou furtados.</h2>
        <Div className="row justify-content-center mt-5 pt-5">
          {BINitems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
