import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { SideMenu } from '../SideMenu';
import { Link } from 'react-router-dom';

export default function Debito() {
  pageTitle('Pesquisa de Débito');

  const funfaceData = [
    {
      title: 'Numeros',
      factNumber: '40K',
    },
    {
      title: 'Numeros',
      factNumber: '50K',
    },
    {
      title: 'Numeros',
      factNumber: '245',
    },
    {
      title: 'Numeros',
      factNumber: '550',
    },
  ];

  const DEitems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_1.webp`,
      title: "Regularização Fiscal e Documental",
      description: "Verificar os débitos de IPVA e licenciamento garante que o veículo esteja em conformidade com as obrigações fiscais e documentais. Isso é vital para asse- gurar que os veículos em questão não apresentem pendências significativas, proporcionando maior confiança nas transações."
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_2.webp`,
      title: "Alerta de Multas e Infrações",
      description: "A inclusão de alertas de multas ajuda a identificar se o veículo possui infrações não pagas. Isso não apenas contribui para a avaliação da integridade do veículo, mas também evita surpresas desagradáveis para os futuros proprietários."
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_3.webp`,
      title: "Informações Detalhadas sobre Débitos",
      description: "Além de identificar a existência de débitos, fornecer informações detalhadas sobre o valor do IPVA, DPVAT, licenciamento e multas é crucial. Isso permite que as associações de proteção veicular tenham uma visão abrangente das obrigações financeiras associadas ao veículo."
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_4.webp`,
      title: "Avaliação de Riscos Financeiros",
      description: "Conhecer o valor do IPVA, DPVAT, licenciamento e multas ajuda a avaliar os riscos financeiros associados ao veículo. Isso é especialmente relevante para evitar indenizações incorretas ou situações em que os custos associados ao veículo são maiores do que o esperado."
    },
    {
      id: 5,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_5.webp`,
      title: "Transparência nas Transações",
      description: "A divulgação clara e transparente dos débitos associados ao veículo contribui para transações mais transparentes e éticas. Isso é benéfico para todas as partes envolvidas, promovendo a confiança e a integridade nos processos de in- denização."
    },
    {
      id: 6,
      img: `${basepath}/img/gallery_images/bbautoconsulta/debito/debito_6.webp`,
      title: "Prevenção contra Ocultações de Informações",
      description: "Ao fornecer informações abrangentes sobre débitos, a plataforma contribui para a prevenção contra ocultações de informações por parte dos proprietá- rios ou terceiros. Isso é essencial para evitar situações em que informações rele- vantes são deliberadamente omitidas."
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Pesquisa de Débito"
        bgSrc={`${basepath}/img/gallery_images/bbautoconsulta/debito/debito_7.webp`}
        pageLinkText="Pesquisa de Débito"
      />
      
      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7 mb-3">
            <SectionHeading
              title="Maior confiança nas transações"
              subtitle="Sobre o seguro"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">A inclusão e análise de débitos relacionados ao IPVA, licenciamento, alerta de multas e outros valores associados são elementos cruciais para uma abordagem abrangente na prevenção de fraudes e na garantia da integridade dos veículos. Aqui estão os motivos pelos quais esses itens são essenciais.</p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Link to="http://www.bbautoconsulta.com.br/" target='_blank'>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                  <span>Realizar Consulta</span>
                </button>
              </Link>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2 mb-3">
            <img
              src={`${basepath}/img/gallery_images/bbautoconsulta/debito/debito_2.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      
      <Div className="container">
        <Div className="row justify-content-center mt-5 pt-5">
          {DEitems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
