import React, { createContext, useContext, useRef, useState } from 'react';

const SlideContext = createContext();

export const useSlideContext = () => {
  return useContext(SlideContext);
};

export const SlideProvider = ({ children }) => {

    const [slideIndexSelected,setSlideIndexSelected] = useState(0)
    const swiperRef = useRef(null)

    const slideSwiper = () => {
        swiperRef.current.swiper.slideTo(slideIndexSelected, 500)
    }

  return (
    <SlideContext.Provider value={{ swiperRef, slideSwiper, setSlideIndexSelected }}>
      {children}
    </SlideContext.Provider>
  );
};
