import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { SideMenu } from '../SideMenu';
import { Link } from 'react-router-dom';

export default function BuscaVeiculos() {
  pageTitle('Busca de Veiculos');

  const funfaceData = [
    {
      title: 'Numeros',
      factNumber: '40K',
    },
    {
      title: 'Numeros',
      factNumber: '50K',
    },
    {
      title: 'Numeros',
      factNumber: '245',
    },
    {
      title: 'Numeros',
      factNumber: '550',
    },
  ];

  const BVitems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/bbautoconsulta/busca/busca_1.webp`,
      title: "Upload da lista de veículos",
      description: "Defina a periodicidade desejada para a busca de veículos roubados ou furta- dos. Com a nossa plataforma, você pode realizar o upload da lista de veículos associados, permitindo uma busca automatizada e eficiente."
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/bbautoconsulta/busca/busca_2.webp`,
      title: "Processamento Automatizado",
      description: "Utilizando tecnologia avançada, nossa plataforma realiza um processamento au- tomatizado para localizar veículos roubados ou furtados. O sistema analisa dados em tempo real, incluindo informações de placas, características do veícu- lo e outras variáveis relevantes para identificação."
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/bbautoconsulta/busca/busca_3.webp`,
      title: "Notificações em Tempo Real",
      description: "Mantenha as associações de proteção veicular informadas com notificações em tempo real. Assim que um veículo é localizado e identificado no pátio legal, nossa plataforma envia automaticamente alertas, permitindo que asassocia- ções ajam prontamente para realizar os procedimentos necessários."
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/bbautoconsulta/busca/busca_5.webp`,
      title: "Integração Simplificada",
      description: "Nossa plataforma é projetada para integração fácil e rápida com os sistemas existentes das associações de proteção veicular. Isso proporciona uma transi- ção suave para a utilização de nossos serviços, garantindo eficácia e eficiência desde o início."
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Busca de Veiculos"
        bgSrc={`${basepath}/img/gallery_images/bbautoconsulta/busca/busca_1.webp`}
        pageLinkText="Busca de Veiculos"
      />

      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6 mb-3">
            <SectionHeading
              title="Busca de veículos"
              subtitle="Roubados e/ou furtados"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">Oferecemos uma solução inovadora e tecnologicamente avançada para maximizar a recuperação de veículos roubados ou furtados, proporcionando segurança e tranquilidade às associações de proteção veicular.</p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Link to="http://www.bbautoconsulta.com.br/" target='_blank'>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                  <span>Realizar Consulta</span>
                </button>
              </Link>
            </SectionHeading>
          </Div>
          <Div className="col-lg-6 mb-3">
            <img
              src={`${basepath}/img/gallery_images/bbautoconsulta/busca/busca_2.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />

      <Div className="container">
        <h2 className="text-center cs-section_title mb-5">Oferecemos uma solução inovadora e tecnologicamente avançada para maximizar a recuperação de veículos roubados ou furtados.</h2>
        <Div className="row justify-content-center mt-5 pt-5">
          {BVitems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
