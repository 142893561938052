import { Link } from "react-router-dom";

export function CustomCardInfo({ data, buttonText, cardLink }) {
    return (
        <div className="card_wrapp">
            <div className="header">
                <img src={data.img} alt="" />
                <h5>{data.title}</h5>
                <p className="text-justify">{data.description}</p>
            </div>
            {/* <Link to={cardLink ? cardLink : "https://bbautoconsulta.com.br/sistema/entrar"} target="_blank">
                <button className="w-100 mt-4 fade-in cs-btn cs-style1 cs-type1" type='button'>
                    <span>{buttonText ? buttonText : "Acessar Consulta"}</span>
                </button>
            </Link> */}
        </div>
    )
}