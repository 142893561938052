import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { SideMenu } from '../SideMenu';

export default function Proprietarios() {
  pageTitle('Pesquisa de Proprietários Anteriores');

  const funfaceData = [
    {
      title: 'Numeros',
      factNumber: '40K',
    },
    {
      title: 'Numeros',
      factNumber: '50K',
    },
    {
      title: 'Numeros',
      factNumber: '245',
    },
    {
      title: 'Numeros',
      factNumber: '550',
    },
  ];

  const PROitems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/bbautoconsulta/proprietarios/proprietarios_1.webp`,
      title: "Verificação de Consistência no Histórico ",
      description: "Ao rastrear os proprietários anteriores, é possível verificar a consistência do his- tórico do veículo. Inconsistências, como alterações frequentes de proprietários em um curto período, podem levantar bandeiras vermelhas e indicar possíveis atividades fraudulentas."
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/bbautoconsulta/proprietarios/proprietarios_2.webp`,
      title: "Identificação de Transferências Suspeitas",
      description: "Analisar os proprietários anteriores ajuda a identificar transferências de proprie- dade que possam parecer suspeitas. Isso inclui casos em que o veículo mudou de mãos repetidamente em um curto espaço de tempo, o que pode sugerir ati- vidades ilícitas."
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/bbautoconsulta/proprietarios/proprietarios_3.webp`,
      title: "Prevenção contra Clonagem de Veículos",
      description: "A clonagem de veículos é uma prática fraudulenta em que placas e identifica- ção são replicadas. Ao rastrear os proprietários anteriores, é possível detectar se um veículo está sendo usado de forma ilegítima ao comparar as informações com registros históricos."
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_4.webp`,
      title: "Estabelecimento de Lastro do Veículo",
      description: "Entender o histórico de proprietários contribui para estabelecer o lastro do veí- culo, fornecendo uma visão mais completa de sua jornada ao longo do tempo. Isso é valioso para avaliar a autenticidade das informações apresentadas."
    },
    {
      id: 5,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_5.webp`,
      title: "Avaliação de Riscos e Sinistros Anteriores",
      description: "Ao conhecer os proprietários anteriores, é possível avaliar se o veículo esteve envolvido em sinistros anteriores. Essa informação é vital para associações de proteção veicular, permitindo uma avaliação mais precisa dos riscos associados à indenização."
    },
    {
      id: 6,
      img: `${basepath}/img/gallery_images/bbautoconsulta/restricoes/restricoes_6.webp`,
      title: "Apoio à Identificação de Fraudes de Identidade",
      description: "A análise dos proprietários anteriores pode fornecer insights sobre a autentici- dade das informações apresentadas. Isso é útil para identificar possíveis frau- des de identidade, onde detalhes do veículo podem ser manipulados para fins ilícitos."
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <PageHeading
        title="Pesquisa de Proprietários Anteriores"
        bgSrc={`${basepath}/img/gallery_images/bbautoconsulta/proprietarios/proprietarios_1.webp`}
        pageLinkText="Pesquisa de Proprietários Anteriores"
      />

      <SideMenu />

      <Div className="container">
        <Div className="row justify-content-center mt-5 pt-5">
          {PROitems.map(item => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>


      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
