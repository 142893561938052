import { qarApi } from "../utils/axios";

export async function sendQarForm(data) {

    const formattedRoutes = data.ufRows.map(route => {
        return {
            quantity: parseInt(route.quantity),
            percentage: parseInt(route.percentage),
            origin: {
                state: route.originState.toUpperCase()
            },
            destination: {
                state: route.destinationState.toUpperCase()
            }
        };
    });

    await qarApi.post(
        "/risk-assessment",
        {
            broker: {
                name: data.formData.brokerName,
                cnpj: data.formData.brokerCnpj,
                susep: data.formData.brokerSusep
            },
            quotation: {
                renewal: data.formData.quotationRenewal,
                request_date: data.formData.quotationRequest_date,
                policy_term: data.formData.quotationPolicy_term,
                new_insurance: data.formData.quotationNew_insurance
            },
            proponent: {
                name: data.formData.proponentName,
                cnpj: data.formData.proponentCnpj,
                phone: data.formData.proponentPhone,
                activity: data.formData.proponentActivity,
                email: data.formData.proponentEmail,
                address: {
                    street: data.formData.proponentAddressState,
                    number: data.formData.proponentAddressNumber,
                    neighborhood: data.formData.proponentAddressNeighborhood,
                    complement: data.formData.proponentAddressComplement,
                    city: data.formData.proponentAddressCity,
                    state: data.formData.proponentAddressState,
                    zip_code: data.formData.proponentAddressZip_code
                }
            },
            fleet: {
                own_vehicles: data.formData.fleetOwn_vehicles,
                autonomous_vehicles_independent_tac: data.formData.fleetAutonomous_vehicles_independent_tac,
                aggregated_vehicles_aggregated_tac: data.formData.fleetAggregated_vehicles_aggregated_tac
            },
            type_of_operation: {
                transfer: data.formData.type_of_operationTransfer,
                distribution: data.formData.type_of_operationDistribution
            },
            trips: {
                types: {
                    intermunicipal: data.formData.tripsTypesIntermunicipal,
                    interstate: data.formData.tripsTypesInterstate,
                    urban: data.formData.tripsTypesUrban
                },
                transported_goods: {
                    dangerous_goods: data.formData.tripsTransported_goodsDangerous_goods === "true" ? true : false,
                    items: data.marketRows
                },
                main_routes: formattedRoutes
            },
            risk_management: {
                items: [{
                    name: data.formData.risk_managementMonitoring,
                    register: data.formData.risk_managementRegister === "true" ? true : false,
                    victimology: data.formData.risk_managementVictimology === "true" ? true : false,
                    monitoring: data.formData.risk_managementMonitoring === "true" ? true : false,
                    tracker: data.formData.risk_managementTracker,
                    technology: data.formData.risk_managementTechnology
                }],
                actuators_used: [
                    {
                        name: "Sensor de desengate de carreta",
                        value: data.formData.risk_managementActuators_usedCarreta
                    },
                    {
                        name: "Sensor de porta",
                        value: data.formData.risk_managementActuators_usedDoor
                    },
                    {
                        name: "Alarmes",
                        value: data.formData.risk_managementActuators_usedAlarms
                    },
                    {
                        name: "Travamento automático do baú",
                        value: data.formData.risk_managementActuators_usedBox
                    },
                    {
                        name: "Botão de pânico",
                        value: data.formData.risk_managementActuators_usedPanicButton
                    },
                    {
                        name: "Sirene",
                        value: data.formData.risk_managementActuators_usedSiren
                    },
                    {
                        name: "Sensor de carona",
                        value: data.formData.risk_managementActuators_usedSensor
                    },
                    {
                        name: "Bloqueio do veículo",
                        value: data.formData.risk_managementActuators_usedBlock
                    },
                    {
                        name: "Outros",
                        value: data.formData.risk_managementActuators_usedOthers
                    }
                ]
            },
            sinister: {
                "12_months": data.formData.sinister12_months,
                "24_months": data.formData.sinister24_months,
                "36_months": data.formData.sinister36_months,
                "48_months": data.formData.sinister48_months
            }
        }
    );
}
