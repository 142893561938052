export const questions = [
    {
      id: 1,
      question: "O que é o Seguro de Transporte RC-V?",
      answer: "O Seguro de Responsabilidade Civil de Veículos (RC-V) é uma novidade no setor de transporte rodoviário de cargas. Com a promulgação da Lei nº 14.599/2023, este seguro tornou-se obrigatório para transportadoras."
    },
    {
      id: 2,
      question: "Para quem é indicado?",
      answer: "Este seguro é indicado para transportadoras, abrangendo a frota da empresa, e para Transportadores Autônomos de Cargas (TAC) subcontratados. O Seguro RC-V da Kovr foi especialmente desenvolvido para atender os Transportadores Autônomos de Carga (TAC)."
    },
    {
      id: 3,
      question: "Quais são as coberturas?",
      answer: "O seguro cobre danos corporais e materiais causados a terceiros, desde que seja comprovada a responsabilidade civil do segurado em acidentes de trânsito involuntários."
    },
    {
      id: 4,
      question: "Qual é o limite de cobertura para danos materiais?",
      answer: "Para danos materiais a terceiros não transportados, o mínimo é de 20.000 DES (Direitos Especiais de Saque), convertidos em reais com base na data da viagem/embarque declarada na averbação."
    },
    {
      id: 5,
      question: "Qual é o limite de cobertura para danos corporais?",
      answer: "Para danos corporais a terceiros não transportados, o mínimo é de 35.000 DES (Direitos Especiais de Saque), convertidos em reais com base na data da viagem/embarque declarada na averbação."
    },
    {
      id: 6,
      question: "Qual é o período de cobertura?",
      answer: "A cobertura começa quando o veículo transportador inicia a viagem principal, efetivamente averbada após o carregamento, e termina com a chegada ao destino final para descarregamento, conforme declarado no documento oficial de averbação."
    },
    {
      id: 7,
      question: "Como funciona a contratação desse seguro?",
      answer: "O Seguro RC-V da Kovr Seguradora é adquirido exclusivamente através de corretores. O corretor encaminha o Questionário de Avaliação de Risco (QAR) preenchido com os dados da transportadora para aprovação da seguradora e posterior emissão da proposta. Após o aceite da proposta de seguro, será realizado o processo de emissão da apólice de seguro. Com a apólice emitida, essas informações serão enviadas para a Simetrias (Software de Averbação) para que eles procedam com a abertura da caixa postal."
    },
    {
      id: 8,
      question: "Como é calculado o valor do prêmio por viagem?",
      answer: "O valor do prêmio varia conforme a origem e o destino da viagem:\nTransporte dentro do mesmo estado: R$ 11,90\nTransporte entre estados da mesma região: R$ 17,90\nTransporte entre estados de regiões diferentes: R$ 24,90"
    },
    {
      id: 9,
      question: "O que é o prêmio do seguro?",
      answer: "O prêmio é o valor pago à seguradora em troca da transferência do risco. No caso deste seguro, o prêmio varia de acordo com as averbações das viagens realizadas."
    },
    {
      id: 10,
      question: "O seguro tem franquia?",
      answer: "Sim, o seguro tem uma franquia (Participação Obrigatória do Segurado) dedutível de 1.600 DES, convertidos em reais com base na data da viagem/embarque declarada na averbação. A franquia se aplica da seguinte forma:\nDanos Materiais: Franquia por sinistro.\nDanos Corporais: Franquia por sinistro."
    },
    {
      id: 11,
      question: "Qual é o prazo para o pagamento da indenização?",
      answer: "Após a entrega completa da documentação exigida pela seguradora, a indenização será paga em até 30 (trinta) dias corridos."
    },
    {
      id: 12,
      question: "Como proceder em caso de sinistro?",
      answer: "Nossa equipe de sinistros está disponível para atender nossos segurados. Assim que tomar conhecimento do sinistro, entre em contato pelo telefone 0800 000 8785, das 8h às 18h, de segunda a sexta-feira, ou pelo e-mail: sinistro.rcv@brasilbeneficios.club."
    },
    {
      id: 13,
      question: "O que são os DES?",
      answer: "DES (Direitos Especiais de Saque) são instrumentos monetários internacionais utilizados como base para estipulação da cobertura mínima de danos corporais e materiais neste seguro. A conversão de DES para reais será feita no dia do sinistro."
    },
    {
      id: 14,
      question: "Qual é o tipo de apólice do seguro RC-V?",
      answer: "A apólice com vigência anual e de faturamento mensal, baseada nas averbações enviadas."
    },
    {
      id: 15,
      question: "Posso personalizar minha apólice de seguro RC-V?",
      answer: "No momento, não há disponibilidade para personalização de coberturas adicionais."
    },
    {
      id: 16,
      question: "O que é averbação?",
      answer: "A averbação é o ato de informar à seguradora os dados referentes ao veículo que realizará a viagem segurada, por meio do MDFe (Manifesto Eletrônico de Documentos Fiscais) ou outro documento fiscal de embarque, confirmando as características das mercadorias transportadas."
    },
    {
      id: 17,
      question: "O que é Risco Decorrido?",
      answer: "Seguro a risco decorrido é uma modalidade de seguro em que a cobertura do risco é ativada ao longo do tempo, conforme o período contratado pelo segurado. Neste modelo, o risco segurado vai sendo 'consumido' progressivamente à medida que o tempo passa."
    }
  ];
  