import { Plus, Trash2 } from "lucide-react";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Dialog from '@radix-ui/react-dialog';
import { sendQarForm } from "../api/send-qar-form";
import { toast } from "react-toastify";

export function SendQalForm() {

    const { register, handleSubmit } = useForm()
    const [isSending,setIsSending] = useState(false)

    const [ufRows, setUfRows] = useState([
        { originState: "", destinationState: "", quantity: "", percentage: "" }
    ])

    const [marketRows, setMarketRows] = useState([
        { name: "", percentage: "" }
    ])

    const containerRef = useRef(null)

    const addUfRow = () => {
        if (ufRows.length < 8) {
            setUfRows([...ufRows, { originState: "", destinationState: "", quantity: "", percentage: "" }])
        }
    }

    const addMarketRow = () => {
        if (marketRows.length < 8) {
            setMarketRows([...marketRows, { name: "", percentage: "" }])
        }
    }

    const removeUfRow = (index) => {
        if (ufRows.length > 1) {
            const updatedRows = ufRows.filter((_, i) => i !== index)
            setUfRows(updatedRows)
        }
    }

    const removeMarketRow = (index) => {
        if (marketRows.length > 1) {
            const updatedRows = marketRows.filter((_, i) => i !== index)
            setMarketRows(updatedRows)
        }
    }

    const handleUfInputChange = (index, field, value) => {
        const updatedRows = ufRows.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );
        setUfRows(updatedRows)
    }

    const handleMarketInputChange = (index, field, value) => {
        const updatedRows = marketRows.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );
        setMarketRows(updatedRows)
    }

    useEffect(() => {
        if (containerRef.current && (ufRows.length > 1 || marketRows.length > 1)) {
            containerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [ufRows, marketRows]);

    async function handleSendQarForm(data) {

        setIsSending(true)

        try {
            await sendQarForm({
                formData: data,
                ufRows,
                marketRows
            })

            setIsSending(false)
            toast.success("Formulário enviado com sucesso. Em breve, nossa equipe entrará em contato com mais informações.")

        } catch(Err) {
            setIsSending(false)
            toast.error("Algo deu errado. Por favor confira os campos novamente ou entre em contato com o suporte.")
        }
    }

    return (
        <form onSubmit={handleSubmit(handleSendQarForm)} className='flex flex-col gap-4 my-6 max-h-[460px] overflow-scroll p-2'>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Dados da corretora</div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="brokerName" className='text-muted-foreground text-sm'>Nome:</label>
                        <input
                            type="text"
                            id='brokerName'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o seu nome:'
                            {...register("brokerName")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="cnpj" className='text-muted-foreground text-sm'>CNPJ:</label>
                        <input
                            type="number"
                            id='cnpj'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o CNPJ:'
                            {...register("brokerCnpj")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="susep" className='text-muted-foreground text-sm'>SUSEP Corretor:</label>
                        <input
                            type="number"
                            id='susep'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o SUSEP Corretor:'
                            {...register("brokerSusep")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Dados da cotação</div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="renovation" className='text-muted-foreground text-sm'>Renovação:</label>
                        <input
                            type="date"
                            id='renovation'
                            className='rounded-sm p-2 stll_field'
                            {...register("quotationRenewal")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="solicitation" className='text-muted-foreground text-sm'>Data da Solicitacão:</label>
                        <input
                            type="date"
                            id='solicitation'
                            className='rounded-sm p-2 stll_field'
                            {...register("quotationRequest_date")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="new_secure" className='text-muted-foreground text-sm'>Seguro novo?</label>
                        <select className="stll_field p-2" name="new_secure" id="new_secure" {...register("quotationNew_insurance")} >
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="vigence" className='text-muted-foreground text-sm'>Vigência da Apólice:</label>
                        <input
                            type="date"
                            id='vigence'
                            className='rounded-sm p-2 stll_field'
                            {...register("quotationPolicy_term")}

                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Dados do proponente</div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentName" className='text-muted-foreground text-sm'>Nome:</label>
                        <input
                            type="text"
                            id='proponentName'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o nome do proponente:'
                            {...register("proponentName")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentCnpj" className='text-muted-foreground text-sm'>CNPJ:</label>
                        <input
                            type="number"
                            id='proponentCnpj'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o cnpj do proponente:'
                            {...register("proponentCnpj")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentPhone" className='text-muted-foreground text-sm'>Telefone:</label>
                        <input
                            type="number"
                            id='proponentPhone'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o telefone do proponente:'
                            {...register("proponentPhone")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentActivity" className='text-muted-foreground text-sm'>Atividade da Empresa:</label>
                        <input
                            type="text"
                            id='proponentActivity'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira a atividade da empresa:'
                            {...register("proponentActivity")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressStreet" className='text-muted-foreground text-sm'>Endereço:</label>
                        <input
                            type="text"
                            id='proponentAddressStreet'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o endereço do proponente:'
                            {...register("proponentAddressStreet")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressNeighborhood" className='text-muted-foreground text-sm'>Bairro:</label>
                        <input
                            type="text"
                            id='proponentAddressNeighborhood'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o bairro do proponente:'
                            {...register("proponentAddressNeighborhood")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressNumber" className='text-muted-foreground text-sm'>Número:</label>
                        <input
                            type="number"
                            id='proponentAddressNumber'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o complemento do proponente:'
                            {...register("proponentAddressNumber")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressComplement" className='text-muted-foreground text-sm'>Complemento:</label>
                        <input
                            type="text"
                            id='proponentAddressComplement'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o complemento do proponente:'
                            {...register("proponentAddressComplement")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressCity" className='text-muted-foreground text-sm'>Cidade:</label>
                        <input
                            type="text"
                            id='proponentAddressCity'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira a cidade do proponente:'
                            {...register("proponentAddressCity")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressState" className='text-muted-foreground text-sm'>Estado:</label>
                        <input
                            type="text"
                            id='proponentAddressState'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o estado do proponente:'
                            {...register("proponentAddressState")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentAddressZip_code" className='text-muted-foreground text-sm'>CEP:</label>
                        <input
                            type="number"
                            id='proponentAddressZip_code'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o cep do proponente:'
                            {...register("proponentAddressZip_code")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="proponentEmail" className='text-muted-foreground text-sm'>E-mail:</label>
                        <input
                            type="email"
                            id='proponentEmail'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o email do proponente:'
                            {...register("proponentEmail")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Frota:</div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="fleetOwn_vehicles" className='text-muted-foreground text-sm'>Veículos Próprios:</label>
                        <input
                            type="number"
                            id='fleetOwn_vehicles'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Quantidade de veículos próprios:'
                            {...register("fleetOwn_vehicles")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="fleetAutonomous_vehicles_independent_tac" className='text-muted-foreground text-sm'>Veículos Autônomos:</label>
                        <input
                            type="number"
                            id='fleetAutonomous_vehicles_independent_tac'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Quantidade de veículos autônomos:'
                            {...register("fleetAutonomous_vehicles_independent_tac")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="fleetAggregated_vehicles_aggregated_tac" className='text-muted-foreground text-sm'>Veículos Agregados:</label>
                        <input
                            type="number"
                            id='fleetAggregated_vehicles_aggregated_tac'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Quantidade de veículos agregados:'
                            {...register("fleetAggregated_vehicles_aggregated_tac")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Tipo de Operação:</div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="type_of_operationTransfer" className='text-muted-foreground text-sm'>Transferência:</label>
                        <input
                            type="number"
                            id='type_of_operationTransfer'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem de transferência:'
                            {...register("type_of_operationTransfer")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="type_of_operationDistribution" className='text-muted-foreground text-sm'>Distribuição:</label>
                        <input
                            type="number"
                            id='type_of_operationDistribution'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem de distribuição:'
                            {...register("type_of_operationDistribution")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Viagens (Últimos 12 Meses)</div>
                <div className="grid md:grid-cols-3 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="tripsTypesIntermunicipal" className='text-muted-foreground text-sm'>Inter Municipal (%):</label>
                        <input
                            type="number"
                            id='tripsTypesIntermunicipal'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Viagens inter municipais:'
                            {...register("tripsTypesIntermunicipal")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="tripsTypesInterstate" className='text-muted-foreground text-sm'>Inter Estadual (%):</label>
                        <input
                            type="number"
                            id='tripsTypesInterstate'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Viagens inter estaduais:'
                            {...register("tripsTypesInterstate")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="tripsTypesUrban" className='text-muted-foreground text-sm'>Urbano (%):</label>
                        <input
                            type="number"
                            id='tripsTypesUrban'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Viagens urbanas:'
                            {...register("tripsTypesUrban")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Principais Percursos</div>
                {ufRows.map((row, index) => (
                    <div
                        key={index}
                        className="grid md:grid-cols-[80px_80px_120px_120px_1fr] gap-4 items-end"
                        ref={index === ufRows.length - 1 ? containerRef : null}
                    >
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`origem-${index}`} className="text-muted-foreground text-sm">
                                Origem (UF)
                            </label>
                            <input
                                type="text"
                                id={`origem-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="UF:"
                                value={row.originState}
                                onChange={(e) => handleUfInputChange(index, "originState", e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`destino-${index}`} className="text-muted-foreground text-sm">
                                Destino (UF)
                            </label>
                            <input
                                type="text"
                                id={`destino-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="UF:"
                                value={row.destinationState}
                                onChange={(e) => handleUfInputChange(index, "destinationState", e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`quantidade-${index}`} className="text-muted-foreground text-sm">
                                Quantidade
                            </label>
                            <input
                                type="number"
                                id={`quantidade-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="Quantidade:"
                                value={row.quantity}
                                onChange={(e) => handleUfInputChange(index, "quantity", e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`porcentagem-${index}`} className="text-muted-foreground text-sm">
                                Porcentagem
                            </label>
                            <input
                                type="number"
                                id={`porcentagem-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="Porcentagem:"
                                value={row.percentage}
                                onChange={(e) => handleUfInputChange(index, "percentage", e.target.value)}
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            {ufRows.length > 1 && (
                                <button
                                    className="bg-red-600 text-white h-10 w-10 rounded-sm flex items-center justify-center"
                                    onClick={() => removeUfRow(index)}
                                >
                                    <Trash2 size={18} />
                                </button>
                            )}
                            {index === ufRows.length - 1 && ufRows.length < 8 && (
                                <button
                                    className="bg-sky-600 text-white h-10 w-10 rounded-sm flex items-center justify-center"
                                    onClick={addUfRow}
                                >
                                    <Plus size={18} />
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Mercadorias Transportadas</div>
                {marketRows.map((row, index) => (
                    <div
                        key={index}
                        className="grid md:grid-cols-[140px_140px_140px_100px] gap-4 items-end"
                        ref={index === marketRows.length - 1 ? containerRef : null}
                    >
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`name-${index}`} className="text-muted-foreground text-sm">
                                Mercadoria:
                            </label>
                            <input
                                type="text"
                                id={`name-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="Insira a mercadoria:"
                                value={row.name}
                                onChange={(e) => handleMarketInputChange(index, "name", e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-0">
                            <label htmlFor={`percentage-${index}`} className="text-muted-foreground text-sm">
                                Porcentagem (%)
                            </label>
                            <input
                                type="number"
                                id={`percentage-${index}`}
                                className="rounded-sm p-2 stll_field"
                                placeholder="Insira a porcentagem:"
                                value={row.percentage}
                                onChange={(e) => handleMarketInputChange(index, "percentage", e.target.value)}
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            {marketRows.length > 1 && (
                                <button
                                    className="bg-red-600 text-white h-10 w-10 rounded-sm flex items-center justify-center"
                                    onClick={() => removeMarketRow(index)}
                                >
                                    <Trash2 size={18} />
                                </button>
                            )}
                            {index === marketRows.length - 1 && marketRows.length < 8 && (
                                <button
                                    className="bg-sky-600 text-white h-10 w-10 rounded-sm flex items-center justify-center"
                                    onClick={addMarketRow}
                                >
                                    <Plus size={18} />
                                </button>
                            )}
                        </div>
                    </div>
                ))}

                <div className='flex flex-col gap-0'>
                    <label htmlFor="tripsTransported_goodsDangerous_goods" className='text-muted-foreground text-sm'>Entre as mercadorias transportadas existe o transporte de mercadorias perigosas?</label>
                    <select className="stll_field p-2" id="tripsTransported_goodsDangerous_goods" {...register("tripsTransported_goodsDangerous_goods")} >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Gerenciamento de risco</div>
                <div className="grid md:grid-cols-3 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementName" className='text-muted-foreground text-sm'>Gerenciadora</label>
                        <input
                            type="text"
                            id='risk_managementName'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira a gerenciadora:'
                            {...register("risk_managementName")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementRegister" className='text-muted-foreground text-sm'>Possui cadastro?</label>
                        <select className="stll_field p-2" id="risk_managementRegister" {...register("risk_managementRegister")} >
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementVictimology" className='text-muted-foreground text-sm'>Possui Vitimologia?</label>
                        <select className="stll_field p-2" id="risk_managementVictimology" {...register("risk_managementVictimology")} >
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementMonitoring" className='text-muted-foreground text-sm'>Possui Monitoramento?</label>
                        <select className="stll_field p-2" id="risk_managementMonitoring" {...register("risk_managementMonitoring")} >
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementTracker" className='text-muted-foreground text-sm'>Rastreador:</label>
                        <input
                            type="text"
                            id='risk_managementTracker'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira o ratreador:'
                            {...register("risk_managementTracker")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="risk_managementTechnology" className='text-muted-foreground text-sm'>Tecnologia:</label>
                        <input
                            type="text"
                            id='risk_managementTechnology'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Insira a tecnologia:'
                            {...register("risk_managementTechnology")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Atuadores utilizados:</div>
                <div className='flex flex-col gap-0'>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedCarreta">
                            Sensor de desengate de carreta
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedCarreta")} id="risk_managementActuators_usedCarreta" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedDoor">
                            Sensor de porta
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedDoor")} id="risk_managementActuators_usedDoor" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedAlarms">
                            Alarmes
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedAlarms")} id="risk_managementActuators_usedAlarms" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedBox">
                            Travamento automático do baú
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedBox")} id="risk_managementActuators_usedBox" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedPanicButton">
                            Botão de pânico
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedPanicButton")} id="risk_managementActuators_usedPanicButton" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedSiren">
                            Sirene
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedSiren")} id="risk_managementActuators_usedSiren" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedSensor">
                            Sensor de carona
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedSensor")} id="risk_managementActuators_usedSensor" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedBlock">
                            Bloqueio do veículo
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedBlock")} id="risk_managementActuators_usedBlock" />
                    </div>
                    <div className="flex items-baseline gap-2">
                        <label htmlFor="risk_managementActuators_usedOthers">
                            Outros, citar
                        </label>
                        <input type="checkbox" {...register("risk_managementActuators_usedOthers")} id="risk_managementActuators_usedOthers" />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <div className="bg-zinc-700 p-2">Sinistralidade</div>
                <div className="grid md:grid-cols-4 gap-4">
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="sinister12_months" className='text-muted-foreground text-sm'>12 meses (%)</label>
                        <input
                            type="number"
                            id='sinister12_months'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem:'
                            {...register("sinister12_months")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="sinister24_months" className='text-muted-foreground text-sm'>24 meses (%)</label>
                        <input
                            type="number"
                            id='sinister24_months'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem:'
                            {...register("sinister24_months")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="sinister36_months" className='text-muted-foreground text-sm'>36 meses (%)</label>
                        <input
                            type="number"
                            id='sinister36_months'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem:'
                            {...register("sinister36_months")}
                        />
                    </div>
                    <div className='flex flex-col gap-0'>
                        <label htmlFor="sinister48_months" className='text-muted-foreground text-sm'>48 meses (%)</label>
                        <input
                            type="number"
                            id='sinister48_months'
                            className='rounded-sm p-2 stll_field'
                            placeholder='Porcentagem:'
                            {...register("sinister48_months")}
                        />
                    </div>
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <button type='submit' className='bg-white text-black px-3 py-2 rounded text-sm' disabled={isSending} >
                    {isSending ? "Enviando.." : "Enviar"}
                </button>
                <Dialog.Close className="bg-gray-500 text-gray-100 px-3 py-2 rounded text-sm">Cancelar</Dialog.Close>
            </div>

        </form>
    )
}