import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { basepath } from '../App';

const PreviousSolicitationModalContext = createContext();

export const SolicitationStorageContext = ({ children }) => {

    const navigate = useNavigate()

    const [keepLastCarroReserva, setAcceptKeepLastCarroReservaSolicitation] = useState(false)
    const [keepLastSeguroApp, setAcceptKeepLastSeguroAppSolicitation] = useState(false)
    const [keepLastFuneral, setAcceptKeepLastFuneralSolicitation] = useState(false)
    const [keepLastVidros, setAcceptKeepLastVidrosSolicitation] = useState(false)
    const [keepLastPet, setAcceptKeepLastPetSolicitation] = useState(false)
    const [keepLastPeqReparos, setAcceptKeepLastPeqReparosSolicitation] = useState(false)

    const [isOnStart, setIsOnStart] = useState(true)
    const [isOnSeguroAPPForm, setIsOnSeguroAPPForm] = useState(false)
    const [isOnCarroReservaForm, setIsOnCarroReservaForm] = useState(false)
    const [isOnFuneralForm, setIsOnFuneralForm] = useState(false)
    const [isOnVidrosForm, setIsOnVidrosForm] = useState(false)
    const [isOnPetForm, setIsOnPetForm] = useState(false)
    const [isOnReparosForm, setIsOnReparosForm] = useState(false)
  
    const handleAPP = () => {
      navigate(`${basepath}/cliente/APPForm`)
      setIsOnStart(false)
      setIsOnCarroReservaForm(false)
      setIsOnFuneralForm(false)
      setIsOnVidrosForm(false)
      setIsOnPetForm(false)
      setIsOnSeguroAPPForm(true)
    }
  
    const resetHandlingFormsPage = () => {
      setIsOnStart(true)
      setIsOnSeguroAPPForm(false)
      setIsOnFuneralForm(false)
      setIsOnVidrosForm(false)
      setIsOnPetForm(false)
      setIsOnReparosForm(false)
      setIsOnCarroReservaForm(false)
    }

    const handleCarroReserva = () => {
      navigate(`${basepath}/cliente/CRForm`)
      setIsOnStart(false)
      setIsOnSeguroAPPForm(false)
      setIsOnFuneralForm(false)
      setIsOnVidrosForm(false)
      setIsOnPetForm(false)
      setIsOnReparosForm(false)
      setIsOnCarroReservaForm(true)
    }

    const handleFuneralForm = () => {
      navigate(`${basepath}/cliente/FForm`)
      setIsOnStart(false)
      setIsOnSeguroAPPForm(false)
      setIsOnCarroReservaForm(false)
      setIsOnVidrosForm(false)
      setIsOnPetForm(false)
      setIsOnReparosForm(false)
      setIsOnFuneralForm(true)
    }

    const handleVidrosForm = () => {
      navigate(`${basepath}/cliente/VForm`)
      setIsOnStart(false)
      setIsOnSeguroAPPForm(false)
      setIsOnCarroReservaForm(false)
      setIsOnFuneralForm(false)
      setIsOnPetForm(false)
      setIsOnReparosForm(false)
      setIsOnVidrosForm(true)
    }

    const handlePetForm = () => {
      navigate(`${basepath}/cliente/PForm`)
      setIsOnStart(false)
      setIsOnSeguroAPPForm(false)
      setIsOnCarroReservaForm(false)
      setIsOnFuneralForm(false)
      setIsOnVidrosForm(false)
      setIsOnReparosForm(false)
      setIsOnPetForm(true)
    }

    const handleReparosForm = () => {
      navigate(`${basepath}/cliente/PRForm`)
      setIsOnStart(false)
      setIsOnSeguroAPPForm(false)
      setIsOnCarroReservaForm(false)
      setIsOnFuneralForm(false)
      setIsOnVidrosForm(false)
      setIsOnPetForm(false)
      setIsOnReparosForm(true)
    }

    function cleanCarroReservaCache() {
        localStorage.removeItem("SinistroEmpresa")
        localStorage.removeItem("BoletimOcorrencia")
        localStorage.removeItem("lastSolicitatedService")
        localStorage.removeItem("CRLVVeiculo")
        localStorage.removeItem("CHNCliente")
        localStorage.removeItem("AutorizacaoReparo")
        localStorage.removeItem("IDEmpresa")
        localStorage.removeItem("SolicitanteEmpresa")
        localStorage.removeItem("CNPJEmpresa")
        localStorage.removeItem("NomeEmpresa")
        localStorage.removeItem("TelefoneEmpresa")
        localStorage.removeItem("EmailEmpresa")
        localStorage.removeItem("Nome")
        localStorage.removeItem("CPF")
        localStorage.removeItem("Email")
        localStorage.removeItem("Telefone")
        localStorage.removeItem("CEP")
        localStorage.removeItem("Logradouro")
        localStorage.removeItem("Bairro")
        localStorage.removeItem("Cidade")
        localStorage.removeItem("alreadyHaveSolicitated")
        localStorage.removeItem("Estado")
        localStorage.removeItem("Numero")
        localStorage.removeItem("Complemento")
        localStorage.removeItem("Placa")
        localStorage.removeItem("EstadoRetirada")
        localStorage.removeItem("CidadeRetirada")
        localStorage.removeItem("DataRetirada")
        localStorage.removeItem("HoraRetirada")
        localStorage.removeItem("NomeResponsavelCartao")
        localStorage.removeItem("CPFResponsavelCartao")
        localStorage.removeItem("QuantidadeDeDiarias")
    }

    function cleanSeguroAppCache () {
      localStorage.removeItem("IDEmpresa")
      localStorage.removeItem("CNPJEmpresa")
      localStorage.removeItem("Nome")
      localStorage.removeItem("SolicitanteEmpresa")
      localStorage.removeItem("TelefoneEmpresa")
      localStorage.removeItem("EmailEmpresa")
      localStorage.removeItem("TelefoneComercial")
      localStorage.removeItem("RG")
      localStorage.removeItem("CPF")
      localStorage.removeItem("Email")
      localStorage.removeItem("CEP")
      localStorage.removeItem("Logradouro")
      localStorage.removeItem("Bairro")
      localStorage.removeItem("Cidade")
      localStorage.removeItem("UF")
      localStorage.removeItem("Numero")
      localStorage.removeItem("Complemento")
      localStorage.removeItem("possuicondutorsecundario")
      localStorage.removeItem("Nome2")
      localStorage.removeItem("CPF2")
      localStorage.removeItem("Telefone2")
      localStorage.removeItem("Email2")
      localStorage.removeItem("Placa")
      localStorage.removeItem("Montadora")
      localStorage.removeItem("Modelo")
      localStorage.removeItem("Ano")
      localStorage.removeItem("Categoria")
      localStorage.removeItem("Chassi")
      localStorage.removeItem("Renavam")
      localStorage.removeItem("LotacaoMaxima")
      localStorage.removeItem("Utilizacao")
      localStorage.removeItem("Cobertura")
      localStorage.removeItem("CapitalSegurado")
      localStorage.removeItem("lastSolicitatedService")

    }

    function cleanFuneralCache () {
      localStorage.removeItem("IDEmpresa")
      localStorage.removeItem("cnpj")
      localStorage.removeItem("SolicitanteEmpresa")
      localStorage.removeItem("TelefoneEmpresa")
      localStorage.removeItem("EmailEmpresa")
      localStorage.removeItem("Nome")
      localStorage.removeItem("CPF")
      localStorage.removeItem("Email")
      localStorage.removeItem("Telefone")
      localStorage.removeItem("Tipo")
      localStorage.removeItem("CapitalSegurado")
      localStorage.removeItem("Anexo1")
      localStorage.removeItem("Anexo2")
      localStorage.removeItem("Anexo3")
      localStorage.removeItem("Anexo4")
      localStorage.removeItem("Anexo5")
      localStorage.removeItem("lastSolicitatedService")
    }

    function cleanVidrosCache () {
      localStorage.removeItem("IDEmpresa")
      localStorage.removeItem("CNPJ")
      localStorage.removeItem("SolicitanteEmpresa")
      localStorage.removeItem("TelefoneEmpresa")
      localStorage.removeItem("EmailEmpresa")
      localStorage.removeItem("Nome")
      localStorage.removeItem("CPF")
      localStorage.removeItem("Email")
      localStorage.removeItem("Telefone")
      localStorage.removeItem("Placa")
      localStorage.removeItem("Chassi")
      localStorage.removeItem("Tipo")
      localStorage.removeItem("Fabricante")
      localStorage.removeItem("modelo")
      localStorage.removeItem("AnoFabricacao")
      localStorage.removeItem("AnoModelo")
      localStorage.removeItem("Estado")
      localStorage.removeItem("Cidade")
      localStorage.removeItem("Peca")
      localStorage.removeItem("Sensor")
      localStorage.removeItem("ModeloVidro")
      localStorage.removeItem("DescricaoSinistro")
      localStorage.removeItem("Anexo1")
      localStorage.removeItem("Anexo2")
      localStorage.removeItem("Anexo3")
      localStorage.removeItem("lastSolicitatedService")
    }

    function cleanPetCache (){
      localStorage.removeItem("Nome")
      localStorage.removeItem("cpf")
      localStorage.removeItem("DataNascimento")
      localStorage.removeItem("Email")
      localStorage.removeItem("Celular")
      localStorage.removeItem("CEP")
      localStorage.removeItem("rua")
      localStorage.removeItem("bairro")
      localStorage.removeItem("cidade")
      localStorage.removeItem("estado")
      localStorage.removeItem("Numero")
      localStorage.removeItem("Complemento")
      localStorage.removeItem("plano")
      localStorage.removeItem("Numero")
      localStorage.removeItem("lastSolicitatedService")
    }

    function cleanPeqReparosCache () {
      localStorage.removeItem("IDEmpresa")
      localStorage.removeItem("CNPJ")
      localStorage.removeItem("SolicitanteEmpresa")
      localStorage.removeItem("TelefoneEmpresa")
      localStorage.removeItem("EmailEmpresa")
      localStorage.removeItem("Nome")
      localStorage.removeItem("CPF")
      localStorage.removeItem("Email")
      localStorage.removeItem("Telefone")
      localStorage.removeItem("Placa")
      localStorage.removeItem("Chassi")
      localStorage.removeItem("Tipo")
      localStorage.removeItem("Fabricante")
      localStorage.removeItem("modelo")
      localStorage.removeItem("AnoFabricacao")
      localStorage.removeItem("AnoModelo")
      localStorage.removeItem("Estado")
      localStorage.removeItem("Cidade")
      localStorage.removeItem("Peca")
      localStorage.removeItem("PecaSerReparadaSinistro")
      localStorage.removeItem("Anexo2")
      localStorage.removeItem("Anexo3")
      localStorage.removeItem("lastSolicitatedService")
    }


  return (
    <PreviousSolicitationModalContext.Provider
      value={{
        resetHandlingFormsPage,
        cleanCarroReservaCache,
        cleanSeguroAppCache,
        cleanFuneralCache,
        cleanVidrosCache,
        cleanPetCache,
        cleanPeqReparosCache,
        keepLastCarroReserva,
        setAcceptKeepLastCarroReservaSolicitation,
        keepLastSeguroApp,
        setAcceptKeepLastSeguroAppSolicitation,
        keepLastFuneral,
        setAcceptKeepLastFuneralSolicitation,
        keepLastVidros,
        setAcceptKeepLastVidrosSolicitation,
        keepLastPet,
        setAcceptKeepLastPetSolicitation,
        keepLastPeqReparos,
        setAcceptKeepLastPeqReparosSolicitation,
        handleAPP,
        handleCarroReserva,
        isOnStart,
        isOnSeguroAPPForm,
        isOnCarroReservaForm,
        isOnFuneralForm,
        handleFuneralForm,
        handleVidrosForm,
        isOnVidrosForm,
        isOnPetForm,
        handlePetForm,
        isOnReparosForm,
        handleReparosForm,
      }}
    >
      {children}
    </PreviousSolicitationModalContext.Provider>
  );
};

export const useSolicitationStorage = () => {
  return useContext(PreviousSolicitationModalContext);
};
